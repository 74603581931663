import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Chip from "@mui/joy/Chip"
import Typography from "@mui/joy/Typography";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import ChipDelete from '@mui/joy/ChipDelete';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

import CircularProgress from '@mui/joy/CircularProgress';
import LinearProgress from '@mui/joy/LinearProgress';
import { ChevronRightRounded, HomeRounded } from "@mui/icons-material";

import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";

export default function SelectOptions() {

   const [loading, setLoading] = useState(false);
   const [optionType, setOptionType] = useState("event");
   const [optionValue, setOptionValue] = useState("");
   const [optionList, setOptionList] = useState<{ [key: string]: { id: number; value: string }[] }>({
      event: [],
      purpose: [],
      head: [],
      gift: [],
   });

   useEffect(() => {
      const fetchAllOptions = async () => {
         setLoading(true);
         try {
            const categories = ["Event", "Purpose", "Head", "Gift"];
            const result: { [key: string]: { id: number; value: string }[] } = {
               event: [],
               purpose: [],
               head: [],
               gift: [],
            };
            for (const type of categories) {
               const res = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/options?type=${type}`, {
                  method: "GET",
                  credentials: "include",
               });
               const data = await res.json();
               result[type] = data;
            }
            setOptionList(result);
         } catch (err) {
            console.error("Error loading options", err);
         } finally {
            setLoading(false);
         }
      };
      fetchAllOptions();
   }, []);

   const handleAddOption = async () => {
      setLoading(true);
      if (!optionValue.trim()) return;
      try {
         const res = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/options`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: JSON.stringify({ type: optionType, value: optionValue }),
         });
         const data = await res.json();
         if (res.ok) {
            setOptionList((prev) => ({
               ...prev,
               [optionType]: Array.isArray(prev[optionType])
                  ? [...prev[optionType], { id: data.optionId, value: optionValue }]
                  : [{ id: data.optionId, value: optionValue }]
            }));
            setOptionValue("");
         }
      } catch (err) {
         console.error("Add option error", err);
      } finally {
         setLoading(false);
      }
   };

   const handleDeleteOption = async (id: number) => {
      setLoading(true);
      console.log("clicked")
      try {
         const res = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/options/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
         });
         if (res.ok) {
            setOptionList((prev) => {
               const updatedList = { ...prev };
               Object.keys(updatedList).forEach((key) => {
                  updatedList[key] = (updatedList[key] || []).filter((v) => v.id !== id);
               });
               return updatedList;
            });
         }
      } catch (err) {
         console.error("Delete option error", err);
      } finally {
         setLoading(false);
      }
   };


   return (
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
         <Header />
         <Sidebar />
         <Box
            component="main"
            className="MainContent"
            sx={{
               pt: { xs: "calc(12px + var(--Header-height))", md: 3 },
               pb: { xs: 2, sm: 2, md: 3 },
               flex: 1,
               display: "flex",
               flexDirection: "column",
               minWidth: 0,
               height: "100dvh",
               gap: 1,
               overflow: "auto",
               bgcolor: "background.body",
            }}
         >
            <Box sx={{ flex: 1, width: "100%" }}>
               <Box
                  sx={{
                     position: "sticky",
                     top: { sm: -100, md: -110 },
                     bgcolor: "background.body",
                     zIndex: 9995,
                  }}
               >
                  <Box sx={{ px: { xs: 2, md: 6 } }}>
                     <Breadcrumbs
                        size="sm"
                        aria-label="breadcrumbs"
                        separator={<ChevronRightRounded fontSize="small" />}
                        sx={{ pl: 0 }}
                     >
                        <Link
                           underline="none"
                           color="neutral"
                           href="#some-link"
                           aria-label="Home"
                        >
                           <HomeRounded />
                        </Link>
                        <Link
                           underline="hover"
                           color="neutral"
                           href="/donation"
                           sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                           Donation
                        </Link>
                        <Typography
                           color="primary"
                           sx={{ fontWeight: 500, fontSize: 12 }}
                        >
                           Options
                        </Typography>
                     </Breadcrumbs>
                     <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                        Options
                     </Typography>
                  </Box>
               </Box>
               <Stack
                  spacing={4}
                  sx={{
                     display: "flex",
                     maxWidth: "800px",
                     mx: "auto",
                     px: { xs: 2, md: 6 },
                     py: { xs: 2, md: 3 },
                  }}
               >
                  <Card>
                     <Box>
                        <Stack
                           direction="row"
                           spacing={3}
                           sx={{ display: { xs: "flex" }, my: 1 }}
                        >
                           <Stack spacing={2} sx={{ flexGrow: 1, py: 1 }}>

                              <Typography level="title-sm">Manage Options</Typography>
                              <Stack direction="row" spacing={2} sx={{ mt: 2, alignItems: "flex-end" }}>
                                 <FormControl sx={{ width: 160 }}>
                                    <FormLabel>Category</FormLabel>
                                    <Select size="sm" value={optionType} onChange={(_, val) => setOptionType(val || "Event")}>
                                       <Option value="Event">Event</Option>
                                       <Option value="Purpose">Purpose</Option>
                                       <Option value="Head">Head</Option>
                                       <Option value="Gift">Gift</Option>
                                    </Select>
                                 </FormControl>
                                 <FormControl sx={{ flexGrow: 1 }}>
                                    <FormLabel>New {optionType}</FormLabel>
                                    <Input
                                       size="sm"
                                       placeholder={`Enter ${optionType.toLocaleLowerCase()}`}
                                       value={optionValue}
                                       onChange={(e) => setOptionValue(e.target.value)}
                                    />
                                 </FormControl>
                                 <Button size="sm" onClick={handleAddOption} sx={{ height: 32, width: 100 }}>Add</Button>
                              </Stack>

                              {loading && <LinearProgress />}
                              
                              {/* Options Display */}
                              {["Event", "Purpose", "Head", "Gift"].map((type) => (
                                 <Box key={type}>
                                    <Typography level="body-sm" fontWeight="lg" mt={2}>
                                       {type.charAt(0).toUpperCase() + type.slice(1)} Options
                                    </Typography>
                                    <Stack direction="row" flexWrap="wrap" mt={1} sx={{gap: 1 }}>
                                       {optionList[type as keyof typeof optionList]?.map((val) => (
                                          <Chip
                                             key={val.id}
                                             endDecorator={<ChipDelete onDelete={() => handleDeleteOption(val.id)} />}
                                             variant="outlined"
                                          >
                                             {val.value}
                                          </Chip>
                                       ))}
                                    </Stack>
                                 </Box>
                              ))}
                           </Stack>
                        </Stack>
                     </Box>

                  </Card>
               </Stack>
            </Box>
         </Box>
      </Box>
   );
}
