import * as React from 'react';
import { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Table, Sheet } from "@mui/joy";
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import LinearProgress from '@mui/joy/LinearProgress';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { FilterAltOffRounded } from '@mui/icons-material';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === 'date') {
    const dateA = new Date(a[orderBy] as string);
    const dateB = new Date(b[orderBy] as string);
    return dateB.getTime() - dateA.getTime();
  }
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

type donation = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: 'asc' | 'desc',
  orderBy: Key
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function RowMenu() {
  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
      >
        <MoreHorizRoundedIcon />
      </MenuButton>
      <Menu size="sm" sx={{ minWidth: 140 }}>
        <MenuItem>Edit</MenuItem>
        <Divider />
        <MenuItem color="danger">Delete</MenuItem>
      </Menu>
    </Dropdown>
  );
}

interface Donation {
  id: number;
  donorID: number;
  date: string;
  amount: number;
  paymentMethod: string;
  name: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  pan: string;
  gift: string;
  purpose: string;
  head: string;
  event: string;
  taxReceiptNo: string
  email: string;
}

export default function DonationTable() {
  const [donation, setOrder] = React.useState<donation>('desc');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [donations, setDonations] = useState<Donation[]>([]);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [originalDonations, setOriginalDonations] = useState<Donation[]>([]);
  const [donationOrder, setDonationOrder] = useState<'asc' | 'desc'>('desc');
  const [donationOrderBy, setDonationOrderBy] = useState<keyof Donation>('id');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (_: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };
  const getLabelDisplayedRowsTo = () => {
    if (filteredDonations.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? filteredDonations.length
      : Math.min(filteredDonations.length, (page + 1) * rowsPerPage);
  };

  const [filterPurpose, setFilterPurpose] = useState("all");
  const [filterHead, setFilterHead] = useState("all");
  const [filterEvent, setFilterEvent] = useState("all");
  const [filterYear, setFilterYear] = useState("all");
  const [purposeOptions, setPurposeOptions] = useState<string[]>([]);
  const [headOptions, setHeadOptions] = useState<string[]>([]);
  const [eventOptions, setEventOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchDonations = async () => {
      try {

        setDataLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/donation`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        if (!response.ok) throw new Error("Failed to fetch donors");

        const data = await response.json();
        setDonations(Array.isArray(data) ? data : []);
        setOriginalDonations(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error("Error fetching donations:", err);
        setError("Failed to fetch donations.");
      } finally {
        setLoading(false);
        setDataLoading(false);
      }
    };

    fetchDonations();
  }, []);

  useEffect(() => {
    const fetchOptions = async (type: string, setter: React.Dispatch<React.SetStateAction<string[]>>) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/options?type=${type}`, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });
        if (response.ok) {
          const data = await response.json();
          setter(Array.isArray(data) ? data.map((opt: any) => opt.value) : []);
        }
      } catch (err) {
        console.error(`Failed to fetch ${type} options`, err);
      }
    };

    fetchOptions("purpose", setPurposeOptions);
    fetchOptions("head", setHeadOptions);
    fetchOptions("event", setEventOptions);
  }, []);

  if (loading) {
    return <Typography>Loading donations...</Typography>;
  }

  if (error) {
    return <Typography color="danger">{error}</Typography>;
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Purpose</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by purpose"
          value={filterPurpose}
          onChange={(_, value) => setFilterPurpose(value || "all")}
        >
          {purposeOptions.map((p) => (
            <Option key={p} value={p}>{p}</Option>
          ))}
        </Select>
      </FormControl>

      <FormControl size="sm">
        <FormLabel>Head</FormLabel>
        <Select
          size="sm"
          placeholder="All"
          value={filterHead}
          onChange={(_, value) => setFilterHead(value || "all")}
        >
          {headOptions.map((h) => (
            <Option key={h} value={h}>{h}</Option>
          ))}
        </Select>
      </FormControl>

      <FormControl size="sm">
        <FormLabel>Event</FormLabel>
        <Select
          size="sm"
          placeholder="All"
          value={filterEvent}
          onChange={(_, value) => setFilterEvent(value || "all")}
        >
          {eventOptions.map((e) => (
            <Option key={e} value={e}>{e}</Option>
          ))}
        </Select>
      </FormControl>

      <FormControl size="sm">
        <FormLabel>Year</FormLabel>
        <Select
          size="sm"
          placeholder="All"
          value={filterYear}
          onChange={(_, value) => setFilterYear(value || "all")}
        >
          <Option value="all">All</Option>
          <Option value="2024-25">April 2024 - March 2025</Option>
          <Option value="2023-24">April 2023 - March 2024</Option>
          <Option value="2022-23">April 2022 - March 2023</Option>
        </Select>
      </FormControl>


      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        onClick={() => {
          setFilterPurpose("all");
          setFilterHead("all");
          setFilterEvent("all");
          setFilterYear("all");
        }}
        sx={{ alignSelf: "end", mt: 'auto' }}
      >
        <FilterAltOffRounded />
      </IconButton>
    </React.Fragment>
  );

  const filteredDonations = donations.filter((d) => {

    const matchPurpose = filterPurpose === "all" || d.purpose === filterPurpose;
    const matchHead = filterHead === "all" || d.head === filterHead;
    const matchEvent = filterEvent === "all" || d.event === filterEvent;
    const matchYear = (() => {
      const date = new Date(d.date);
      if (filterYear === "2024-25") {
        return date >= new Date("2024-04-01") && date < new Date("2025-04-01");
      }
      if (filterYear === "2023-24") {
        return date >= new Date("2023-04-01") && date < new Date("2024-04-01");
      }
      if (filterYear === "2022-23") {
        return date >= new Date("2022-04-01") && date < new Date("2023-04-01");
      }
      return true;
    })();

    return matchPurpose && matchHead && matchEvent && matchYear;
  });

  const searchDonations = async (query: string) => {
    try {
      setDataLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/search/donation?q=${encodeURIComponent(query)}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to search donations");
      const result = await response.json();
      setDonations(Array.isArray(result) ? result : []);
      setDataLoading(false);
    } catch (err) {
      console.error("Search error:", err);
      setDataLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{ display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1 }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for donation</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length >= 1) {
                searchDonations(e.target.value);
              } else if (e.target.value.length === 0) {
                setDonations(originalDonations);
              }
            }}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          minHeight: 0,
        }}
      >
        {dataLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Box
          sx={{

            width: '100%',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
              '& tr > *:last-child': {
              position: 'sticky',
              right: 0,
              bgcolor: 'var(--TableCell-headBackground)',
            },
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                  <Checkbox
                    size="sm"
                    indeterminate={
                      selected.length > 0 && selected.length !== (donations?.length || 0)
                    }
                    checked={selected.length === (donations?.length || 0)}
                    onChange={(event) => {
                      setSelected(
                        event.target.checked ? (donations || []).map((donation) => donation.id.toString()) : [],
                      );
                    }}
                    color={
                      selected.length > 0 || selected.length === (donations?.length || 0)
                        ? 'primary'
                        : undefined
                    }
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </th>
                <th style={{ width: 70, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'id' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('id');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      {
                        fontWeight: 'lg',
                        '& svg': {
                          transition: '0.2s',
                          transform:
                            donationOrderBy === 'id' && donationOrder === 'desc'
                              ? 'rotate(0deg)'
                              : 'rotate(180deg)',
                        },
                      },
                      donationOrderBy === 'id' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    ID
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'date' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('date');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'date' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Date
                  </Link>
                </th>
                <th style={{ width: 200, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'name' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('name');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'name' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Name
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'amount' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('amount');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'amount' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Amount
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'phone' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('phone');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'phone' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Phone
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'gift' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('gift');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'gift' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Gift
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'purpose' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('purpose');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'purpose' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Purpose
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'head' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('head');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'head' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Head
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'event' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('event');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'event' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Event
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'city' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('city');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'city' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    City
                  </Link>
                </th>
                <th style={{ width: 150, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donationOrderBy === 'paymentMethod' && donationOrder === 'asc';
                      setDonationOrder(isAsc ? 'desc' : 'asc');
                      setDonationOrderBy('paymentMethod');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donationOrderBy === 'paymentMethod' && donationOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Payment Method
                  </Link>
                </th>
                <th aria-label="last" style={{ width: 80,  padding: '12px 6px' }} />
              </tr>
            </thead>
            <tbody>
              {filteredDonations.length > 0 ? (
                [...filteredDonations]
                  .sort(getComparator(donationOrder, donationOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((donation) => (
                    <tr key={donation.id}>
                      <td style={{ textAlign: 'center', width: 120 }}>
                        <Checkbox
                          size="sm"
                          checked={selected.includes(donation.id.toString())}
                          color={selected.includes(donation.id.toString()) ? 'primary' : undefined}
                          onChange={(event) => {
                            setSelected((ids) =>
                              event.target.checked ? ids.concat(donation.id.toString()) : ids.filter((itemId) => itemId !== donation.id.toString()),
                            );
                          }}
                          slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                          sx={{ verticalAlign: 'text-bottom' }}
                        />
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.id}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">
                          {new Date(donation.date).toLocaleDateString("en-GB")}
                        </Typography>
                      </td>
                      <td>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                          <Avatar size="sm">{donation.name ? donation.name.split(' ').map(word => word[0]).join('').toUpperCase() : '-'}</Avatar>
                          <div>
                            <Typography level="body-xs">{donation.name ?? '-'}</Typography>
                            <Typography level="body-xs">{donation.email ?? '-'}</Typography>
                          </div>
                        </Box>
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.amount ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.phone ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.gift ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.purpose ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.head ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.event ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.city ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donation.paymentMethod ?? '-'}</Typography>
                      </td>
                      <td>
                        <Box sx={{ display: 'flex', gap: 2, px: 1, alignItems: 'center' }}>
                        <Link
                          level="body-xs"
                          component="a"
                          href={`/donation/details?id=${donation.id}`}
                        >
                          View
                        </Link>
                        </Box>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={8} style={{ textAlign: "center" }}>No donation found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Box>
        <Table>
          <tfoot>
            <tr>
              <td colSpan={13} style={{ position: 'sticky', bottom: 0, background: '#fff', zIndex: 1, backgroundColor: 'var(--joy-palette-background-level1)' }}>
                <Box
                  sx={{
                    maxWidth: '100%',
                    overflowX: 'auto',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      justifyContent: 'flex-end',
                      px: 2,
                      py: 1,
                      minWidth: '600px',
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                    </FormControl>
                    <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
                      {`${filteredDonations.length === 0 ? 0 : page * rowsPerPage + 1}–${getLabelDisplayedRowsTo()} of ${filteredDonations.length}`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          filteredDonations.length !== -1
                            ? page >= Math.ceil(filteredDonations.length / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}

