import * as React from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { CssVarsProvider as JoyCssVarsProvider, extendTheme, useColorScheme } from "@mui/joy/styles";
import GlobalStyles from "@mui/joy/GlobalStyles";
//import CssBaseline from "@mui/joy/CssBaseline";
import { StyledEngineProvider } from '@mui/joy/styles';

import {
  extendTheme as materialExtendTheme,
  CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const materialTheme = materialExtendTheme();

import Dashboard from "./pages/Dashboard";
import Donation from "./pages/Donation";
import SelectOptions from "./pages/SelectOptions";
import CreateDonation from "./pages/CreateDonation";
import EditDonation from "./pages/EditDonation";
import DonationDetails from "./pages/DonationDetails";
import Donor from "./pages/Donor";
import EditDonor from "./pages/EditDonor";
import Report from "./pages/Report";
import Error403 from "./pages/Error403";
import NotFound from "./pages/NotFound";

const customTheme = extendTheme({
  colorSchemes: {
    dark: {
      palette: {
        mode: "dark",
      },
    },
  },
});

// add router here serve signin page at "/signin" route with all necessary props

export default function App() {
  return (
    <StyledEngineProvider injectFirst>
      <Router>
        {/* <CssVarsProvider theme={customTheme} disableTransitionOnChange> */}
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
          <JoyCssVarsProvider>
            <CssBaseline enableColorScheme />
            <GlobalStyles
              styles={{
                body: {
                  fontFamily: "Roboto, sans-serif",
                },
              }}
            />
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/donation" element={<Donation />} />
              <Route path="/donation/create" element={<CreateDonation />} />
              <Route path="/donation/options" element={<SelectOptions />} />
              <Route path="/donation/details" element={<DonationDetails />} />
              <Route path="/donation/details/edit" element={<EditDonation />} />
              <Route path="/donation/donor" element={<Donor />} />
              <Route path="/donation/donor/details" element={<EditDonor />} />
              <Route path="/report" element={<Report />} />
              <Route path="/unauthorised" element={<Error403 />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
        {/* </CssVarsProvider> */}
      </Router>
    </StyledEngineProvider>
  );
}
