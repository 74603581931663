import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/joy";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Typography level="h1" fontSize="2rem" sx={{ mb: 2 }}>
        404 - Page Not Found
      </Typography>
      <Typography level="body-md" sx={{ mb: 4 }}>
        The page you are looking for does not exist or has been moved.
      </Typography>
      <Button
        onClick={() => navigate("/")}
        variant="soft"
        color="primary"
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFound;
