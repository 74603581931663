import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/joy";

const Error403: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <Typography level="h1" fontSize="2rem" sx={{ mb: 2 }}>
        403 - Not Authorised
      </Typography>
      <Typography level="body-md" sx={{ mb: 4 }}>
        Looks like you have insufficient permission to access this page. Email at vansh@iskconkanpur.org for more info.
      </Typography>
      <Button
        onClick={() => window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signout`}
        variant="soft"
        color="primary"
      >
        Sign out
      </Button>
    </Box>
  );
};

export default Error403;
