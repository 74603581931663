import * as React from "react";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import states from "../data/states.json";
import Typography from "@mui/joy/Typography";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import Autocomplete from "@mui/joy/Autocomplete";
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

import CircularProgress from '@mui/joy/CircularProgress';
import { ChevronRightRounded, HomeRounded } from "@mui/icons-material";

import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";

export default function EditDonor() {
   const [isLoading, setIsLoading] = useState(true);

   const [donorData, setDonorData] = useState({
      id: "",
      name: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "India",
      pincode: "",
      pan: "",
      email: "",
      donorType: "",
   });

   const [errors, setErrors] = useState({
      name: "",
      phone: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      pan: "",
   });

   const [isSubmitting, setIsSubmitting] = useState(false);
   const [isEditing, setIsEditing] = useState(false);
   const [isFormEditable, setIsFormEditable] = useState(true);
   const [message, setMessage] = useState<string | null>(null);
   const [error, setError] = useState<string | null>(null);

   const [searchParams] = useSearchParams();
   const donorIdFromUrl = searchParams.get("id");

   useEffect(() => {
      const fetchDonorById = async (id: string) => {
         setIsLoading(true);
         try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/donor/${id}`, {
               method: "GET",
               headers: { "Content-Type": "application/json" },
               credentials: "include",
            });

            if (!response.ok) {
               if (response.status === 401) {
                  const redirectUrl = encodeURIComponent(window.location.href);
                  window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
                  return;
               }
               throw new Error("Failed to fetch donor details.");
            }

            const data = await response.json();
            setDonorData(data);
            setOriginalDonorData(data);
            setShowDonorForm(true);
            setIsFormEditable(false);
            setIsEditing(false);
         } catch (error) {
            console.error("Error fetching donor details:", error);
            setError("Failed to load donor details.");
         } finally {
            setIsLoading(false);
         }
      };

      if (donorIdFromUrl) {
         fetchDonorById(donorIdFromUrl);
      }
   }, [donorIdFromUrl]);

   useEffect(() => {
      if (message || error) {
         const timer = setTimeout(() => {
            setMessage(null);
            setError(null);
         }, 3000);
         return () => clearTimeout(timer);
      }
   }, [message, error]);
   const [originalDonorData, setOriginalDonorData] = useState(donorData);
   const [showDonorForm, setShowDonorForm] = useState(false);
   const hasErrors = Object.values(errors).some((error) => error !== "") || !donorData.name || !donorData.phone;

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      let errorMsg = "";
      let validatedValue = value;

      switch (name) {
         case "name":
            if (!/^[A-Za-z0-9 ]*$/.test(value) || value.length > 24) {
               errorMsg = "Max 24 characters. Only letters, numbers, and spaces allowed.";
            }
            validatedValue = value.slice(0, 24);
            break;

         case "phone":
            if (!/^[1-9][0-9]{9}$/.test(value)) {
               errorMsg = "Must be exactly 10 digits and cannot start with 0.";
            }
            validatedValue = value.slice(0, 10);
            break;

         case "email":
            if (!/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(value)) {
               errorMsg = "Enter a valid email address.";
            }
            break;

         case "address":
            if (!/^[a-zA-Z0-9 ,\-\/]*$/.test(value) || value.length > 50) {
               errorMsg = "Max 50 characters. Only letters, numbers, ',', '-', and '/' allowed.";
            }
            validatedValue = value.slice(0, 50);
            break;

         case "city":
            validatedValue = value.toUpperCase();
            if (!/^[A-Z ]*$/.test(validatedValue) || validatedValue.length > 20) {
               errorMsg = "Max 20 characters. Only uppercase letters and spaces allowed.";
            }
            validatedValue = validatedValue.slice(0, 20);
            break;

         case "pincode":
            if (!/^[0-9]{0,6}$/.test(value)) {
               errorMsg = "Must be exactly 6 digits.";
            }
            validatedValue = value.slice(0, 6);
            break;

         case "pan":
            validatedValue = value.toUpperCase();
            if (!/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(validatedValue)) {
               errorMsg = "PAN must be exactly 10 characters: 5 letters, 4 digits, 1 letter.";
            }
            validatedValue = validatedValue.slice(0, 10);
            break;

         default:
            break;
      }

      setErrors((prev) => ({ ...prev, [name]: errorMsg }));
      setDonorData((prev) => ({ ...prev, [name]: validatedValue }));
   };

   const handleSelectChange = (event: any, newValue: string | null, field: string) => {
      setDonorData((prev) => ({ ...prev, [field]: newValue || "" }));
   };

   const handleUpdateDonor = async () => {
      setIsSubmitting(true);
      setMessage(null);
      setError(null);

      try {
         const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/donor/${donorData.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(donorData),
            credentials: "include",
         });

         if (!response.ok) {
            if (response.status === 401) {
               const redirectUrl = encodeURIComponent(window.location.href);
               window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
               return;
            }
            throw new Error("Failed to update donor.");
         }

         setMessage("Donor updated successfully!");
         setOriginalDonorData(donorData); // Update original data
         setIsFormEditable(false); // Make form fields read-only
         setIsEditing(false); // Exit edit mode
      } catch (err) {
         setError("Error updating donor. Please try again.");
      } finally {
         setIsSubmitting(false);
      }
   };


   return (
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
         <Header />
         <Sidebar />
         <Box
            component="main"
            className="MainContent"
            sx={{
               pt: { xs: "calc(12px + var(--Header-height))", md: 3 },
               pb: { xs: 2, sm: 2, md: 3 },
               flex: 1,
               display: "flex",
               flexDirection: "column",
               minWidth: 0,
               height: "100dvh",
               gap: 1,
               overflow: "auto",
               bgcolor: "background.body",
            }}
         >
            <Box sx={{ flex: 1, width: "100%" }}>
               <Box
                  sx={{
                     position: "sticky",
                     top: { sm: -100, md: -110 },
                     bgcolor: "background.body",
                     zIndex: 9995,
                  }}
               >
                  <Box sx={{ px: { xs: 2, md: 6 } }}>
                     <Breadcrumbs
                        size="sm"
                        aria-label="breadcrumbs"
                        separator={<ChevronRightRounded fontSize="small" />}
                        sx={{ pl: 0 }}
                     >
                        <Link
                           underline="none"
                           color="neutral"
                           href="#some-link"
                           aria-label="Home"
                        >
                           <HomeRounded />
                        </Link>
                        <Link
                           underline="hover"
                           color="neutral"
                           href="/donation"
                           sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                           Donation
                        </Link>
                        <Link
                           underline="hover"
                           color="neutral"
                           href="/donation/donor"
                           sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                           Donor
                        </Link>
                        <Typography
                           color="primary"
                           sx={{ fontWeight: 500, fontSize: 12 }}
                        >
                           ID - {donorIdFromUrl}
                        </Typography>
                     </Breadcrumbs>
                     <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                        Donor Profile
                     </Typography>
                  </Box>
               </Box>
               <Stack
                  spacing={4}
                  sx={{
                     display: "flex",
                     maxWidth: "800px",
                     mx: "auto",
                     px: { xs: 2, md: 6 },
                     py: { xs: 2, md: 3 },
                  }}
               >
                  <Card>
                     <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Donor Details</Typography>
                        <Typography level="body-sm">
                           View and edit donor details here.
                        </Typography>
                     </Box>
                     {/* Doner Profile */}
                     {isLoading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                           <CircularProgress />
                        </Box>
                     ) : (showDonorForm || donorData.id) ? (
                        <Stack
                           direction="row"
                           spacing={3}
                           sx={{ display: { xs: "flex" }, my: 1 }}
                        >
                           <Stack spacing={2} sx={{ flexGrow: 1 }}>
                              <Stack direction="row" spacing={2}>
                                 <FormControl sx={{ flexGrow: 2 }} error={!!errors.name}>
                                    <FormLabel >Name</FormLabel>
                                    <Input
                                       size="sm"
                                       placeholder="Full name"
                                       name="name"
                                       value={donorData.name}
                                       onChange={handleInputChange}
                                       readOnly={!isFormEditable}
                                    />
                                    {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                                 </FormControl>


                                 <FormControl sx={{ flexGrow: 1 }} error={!!errors.phone}>
                                    <FormLabel>Phone</FormLabel>
                                    <Input
                                       size="sm"
                                       placeholder="e.g., 1234567890"
                                       name="phone"
                                       value={donorData.phone}
                                       onChange={handleInputChange}
                                       readOnly={!isFormEditable}
                                    />
                                    {errors.phone && <FormHelperText>{errors.phone}</FormHelperText>}
                                 </FormControl>

                                 <FormControl sx={{ flexGrow: 1 }} error={!!errors.email}>
                                    <FormLabel>Email</FormLabel>
                                    <Input
                                       size="sm"
                                       type="email"
                                       placeholder="username@example.com"
                                       name="email"
                                       value={donorData.email}
                                       onChange={handleInputChange}
                                       readOnly={!isFormEditable}
                                    />
                                    {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                                 </FormControl>
                              </Stack>
                              <Stack direction="row" spacing={2}>
                                 <FormControl sx={{ flexGrow: 1 }} error={!!errors.address}>
                                    <FormLabel>Address</FormLabel>
                                    <Input
                                       size="sm"
                                       placeholder="e.g., 1,000"
                                       name="address"
                                       value={donorData.address}
                                       onChange={handleInputChange}
                                       readOnly={!isFormEditable}
                                    />
                                    {errors.address && <FormHelperText>{errors.address}</FormHelperText>}
                                 </FormControl>
                                 <FormControl error={!!errors.city}>
                                    <FormLabel>City</FormLabel>
                                    <Input
                                       size="sm"
                                       placeholder="e.g., KANPUR"
                                       name="city"
                                       value={donorData.city}
                                       onChange={handleInputChange}
                                       readOnly={!isFormEditable}
                                    />
                                    {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
                                 </FormControl>
                              </Stack>
                              <Stack direction="row" spacing={2}>
                                 <FormControl>
                                    <FormLabel>State</FormLabel>
                                    <Select
                                       size="sm"
                                       placeholder="Select"
                                       value={donorData.state}
                                       onChange={(event, newValue) => handleSelectChange(event, newValue, "state")}
                                       disabled={!isFormEditable}
                                    >
                                       {states.map((state) => (
                                          <Option key={state} value={state}>{state}</Option>
                                       ))}
                                    </Select>
                                 </FormControl>
                                 <FormControl error={!!errors.pincode}>
                                    <FormLabel>Pincode</FormLabel>
                                    <Input
                                       size="sm"
                                       placeholder="e.g., 000000"
                                       name="pincode"
                                       value={donorData.pincode}
                                       onChange={handleInputChange}
                                       readOnly={!isFormEditable}
                                    />
                                    {errors.pincode && <FormHelperText>{errors.pincode}</FormHelperText>}
                                 </FormControl>
                                 <FormControl error={!!errors.pan}>
                                    <FormLabel>PAN</FormLabel>
                                    <Input
                                       size="sm"
                                       placeholder="ABCDE1234F"
                                       name="pan"
                                       value={donorData.pan}
                                       onChange={handleInputChange}
                                       readOnly={!isFormEditable}
                                    />
                                    {errors.pan && <FormHelperText>{errors.pan}</FormHelperText>}
                                 </FormControl>
                                 <FormControl sx={{ flexGrow: 1 }}>
                                    <FormLabel>Type</FormLabel>
                                    <Select
                                       size="sm"
                                       placeholder="Select"
                                       value={donorData.donorType}
                                       onChange={(event, newValue) => handleSelectChange(event, newValue, "donorType")}
                                       disabled={!isFormEditable}
                                    >
                                       <Option value="Individual">Individual</Option>
                                       <Option value="Corporate">Corporate</Option>
                                       <Option value="Non Profit">Non Profit</Option>
                                       <Option value="Other">Other</Option>
                                    </Select>
                                 </FormControl>
                              </Stack>
                           </Stack>
                        </Stack>
                     ) : (
                        <Typography level="body-sm" sx={{ mt: 2, textAlign: "center" }}>
                           Can't find any donor with this ID.
                        </Typography>
                     )}
                     {message && <Typography level="body-sm" sx={{ color: "green", mt: 1 }}>{message}</Typography>}
                     {error && <Typography level="body-sm" sx={{ color: "red", mt: 1 }}>{error}</Typography>}
                     <CardOverflow>
                        {(showDonorForm || donorData.id) && (
                           <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                              {isFormEditable && (
                                 <Button
                                    size="sm"
                                    variant="outlined"
                                    color="neutral"
                                    onClick={() => {
                                       if (!originalDonorData.id) {
                                          setShowDonorForm(false); // Show "Add New" button again
                                          setDonorData({
                                             id: "",
                                             name: "",
                                             phone: "",
                                             address: "",
                                             city: "",
                                             state: "",
                                             country: "",
                                             pincode: "",
                                             pan: "",
                                             email: "",
                                             donorType: "",
                                          });
                                       } else {
                                          setDonorData(originalDonorData);
                                          setIsEditing(false);
                                          setIsFormEditable(false);
                                       }
                                    }}
                                 >
                                    Cancel
                                 </Button>
                              )}
                              {donorData.id ? (
                                 isEditing ? (
                                    <Button size="sm" variant="solid" onClick={handleUpdateDonor} disabled={isSubmitting || hasErrors}>
                                       {isSubmitting ? "Updating..." : "Update"}
                                    </Button>
                                 ) : (
                                    <Button size="sm" variant="outlined" onClick={() => {
                                       setOriginalDonorData(donorData); // Store the current donor data before editing
                                       setIsEditing(true);
                                       setIsFormEditable(true);
                                    }}>
                                       Edit
                                    </Button>
                                 )
                              ) : (
                                 null
                              )}
                           </CardActions>
                        )}
                     </CardOverflow>
                  </Card>
               </Stack>
            </Box>
         </Box>
      </Box>
   );
}

