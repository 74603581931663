import * as React from 'react';
import { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Table, Sheet } from "@mui/joy";
import { ColorPaletteProp } from '@mui/joy/styles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Link from '@mui/joy/Link';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Checkbox from '@mui/joy/Checkbox';
import IconButton, { iconButtonClasses } from '@mui/joy/IconButton';
import LinearProgress from '@mui/joy/LinearProgress';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Dropdown from '@mui/joy/Dropdown';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import BlockIcon from '@mui/icons-material/Block';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { FilterAltOffRounded } from '@mui/icons-material';


function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (orderBy === 'date') {
    const dateA = new Date(a[orderBy] as string);
    const dateB = new Date(b[orderBy] as string);
    return dateB.getTime() - dateA.getTime();
  }
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

type donor = 'asc' | 'desc';

function getComparator<Key extends keyof Donor>(
  order: 'asc' | 'desc',
  orderBy: Key
): (a: Donor, b: Donor) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

interface Donor {
  id: number;
  donorType?: string;
  name: string;
  phone: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  pincode?: string;
  pan?: string;
  email?: string;
  whatsappGroup?: boolean;
  note?: string;
  createdAt?: string;
  updatedAt?: string;
}

export default function DonorTable() {
  const [donor, setOrder] = React.useState<donor>('desc');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [open, setOpen] = React.useState(false);
  const [hotlistFilter, setHotlistFilter] = useState<string>("all"); // Options: "all", "1", "3", "6", "12"
  const [donors, setDonors] = useState<Donor[]>([]);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [originalDonors, setOriginalDonors] = useState<Donor[]>([]);
  const [donorOrder, setDonorOrder] = useState<'asc' | 'desc'>('desc');
  const [donorOrderBy, setDonorOrderBy] = useState<keyof Donor>('id');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (_: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };
  const getLabelDisplayedRowsTo = () => {
    if (filteredDonors.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? filteredDonors.length
      : Math.min(filteredDonors.length, (page + 1) * rowsPerPage);
  };

  const [filterDonorType, setFilterDonorType] = useState("all");
  const [donorTypeOptions, setDonorTypeOptions] = useState<string[]>(["Individual", "Corporate", "Non Profit", "Other"]);

  useEffect(() => {
    const fetchDonors = async () => {
      try {

        setDataLoading(true);

        let url = `${process.env.REACT_APP_BACKEND_API_URL}/api/donor`;
        if (hotlistFilter !== "all") {
          url = `${process.env.REACT_APP_BACKEND_API_URL}/api/donor/hotlist?months=${hotlistFilter}`;
        }

        const response = await fetch(
          url, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        });

        if (!response.ok) {
          if (response.status === 401) {
            const redirectUrl = encodeURIComponent(window.location.href);
            window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
            return;
          }
          throw new Error("Failed to save donation.");
        }

        const data = await response.json();
        setDonors(Array.isArray(data) ? data : []);
        setOriginalDonors(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error("Error fetching donors:", err);
        setError("Failed to fetch donors.");
      } finally {
        setLoading(false);
        setDataLoading(false);
      }
    };

    fetchDonors();
  }, [hotlistFilter]);

  if (loading) {
    return <Typography>Loading donors...</Typography>;
  }

  if (error) {
    return <Typography color="danger">{error}</Typography>;
  }

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Donor Type</FormLabel>
        <Select
          size="sm"
          placeholder="Filter by donor type"
          value={filterDonorType}
          onChange={(_, value) => setFilterDonorType(value || "all")}
        >
          {donorTypeOptions.map((p) => (
            <Option key={p} value={p}>{p}</Option>
          ))}
        </Select>
      </FormControl>

      <FormControl size="sm">
        <FormLabel>Hotlist</FormLabel>
        <Select
          size="sm"
          value={hotlistFilter}
          onChange={(_, value) => setHotlistFilter(value || "all")}
        >
          <Option value="all">All Donors</Option>
          <Option value="1">Last 1 month</Option>
          <Option value="3">Last 3 months</Option>
          <Option value="6">Last 6 months</Option>
          <Option value="12">Last 12 months</Option>
        </Select>
      </FormControl>

      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        onClick={() => {
          setFilterDonorType("all");
          setHotlistFilter("all");
        }}
        sx={{ alignSelf: "end", mt: 'auto' }}
      >
        <FilterAltOffRounded />
      </IconButton>
    </React.Fragment>
  );

  const filteredDonors = donors.filter((d) => {

    const matchDonorType = filterDonorType === "all" || d.donorType === filterDonorType;

    return matchDonorType;
  });

  const searchDonors = async (query: string) => {
    try {
      setDataLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/search/donor?q=${query}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to search donors");
      const result = await response.json();
      setDonors(Array.isArray(result) ? result : []);
      setDataLoading(false);
    } catch (err) {
      console.error("Search error:", err);
      setDataLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{ display: { xs: 'flex', sm: 'none' }, my: 1, gap: 1 }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: 'sm',
          py: 2,
          display: { xs: 'flex' },
          flexWrap: 'wrap',
          gap: 1.5,
          '& > *': {
            minWidth: { xs: '120px', md: '160px' },
          },
        }}
      >
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for donor</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            startDecorator={<SearchIcon />}
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value.length >= 1) {
                searchDonors(e.target.value);
              } else if (e.target.value.length === 0) {
                setDonors(originalDonors);
              }
            }}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: 'none', sm: 'initial' },
          width: '100%',
          borderRadius: 'sm',
          flexShrink: 1,
          minHeight: 0,
        }}
      >
        {dataLoading && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
        )}
        <Box
          sx={{

            width: '100%',
            flexShrink: 1,
            overflow: 'auto',
            minHeight: 0,
          }}
        >
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
              '--Table-headerUnderlineThickness': '1px',
              '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
              '--TableCell-paddingY': '4px',
              '--TableCell-paddingX': '8px',
              '& tr > *:last-child': {
                position: 'sticky',
                right: 0,
                bgcolor: 'var(--TableCell-headBackground)',
              },
            }}
          >
            <thead>
              <tr>
                <th style={{ width: 48, textAlign: 'center', padding: '12px 6px' }}>
                  <Checkbox
                    size="sm"
                    indeterminate={
                      selected.length > 0 && selected.length !== (donors?.length || 0)
                    }
                    checked={selected.length === (donors?.length || 0)}
                    onChange={(event) => {
                      setSelected(
                        event.target.checked ? (donors || []).map((donor) => donor.id.toString()) : [],
                      );
                    }}
                    color={
                      selected.length > 0 || selected.length === (donors?.length || 0)
                        ? 'primary'
                        : undefined
                    }
                    sx={{ verticalAlign: 'text-bottom' }}
                  />
                </th>
                <th style={{ width: 70, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'id' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('id');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      {
                        fontWeight: 'lg',
                        '& svg': {
                          transition: '0.2s',
                          transform:
                            donorOrderBy === 'id' && donorOrder === 'desc'
                              ? 'rotate(0deg)'
                              : 'rotate(180deg)',
                        },
                      },
                      donorOrderBy === 'id' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    ID
                  </Link>
                </th>
                <th style={{ width: 150, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'name' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('name');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'name' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Name
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'phone' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('phone');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'phone' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Phone
                  </Link>
                </th>
                <th style={{ width: 150, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'email' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('email');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'email' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Email
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'city' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('city');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'city' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    City
                  </Link>
                </th>
                <th style={{ width: 150, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'address' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('address');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'address' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Address
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'pan' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('pan');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'pan' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    PAN
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'donorType' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('donorType');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'donorType' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Type
                  </Link>
                </th>
                <th style={{ width: 120, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'state' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('state');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'state' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    State
                  </Link>
                </th>
                <th style={{ width: 100, padding: '12px 6px' }}>
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    onClick={() => {
                      const isAsc = donorOrderBy === 'pincode' && donorOrder === 'asc';
                      setDonorOrder(isAsc ? 'desc' : 'asc');
                      setDonorOrderBy('pincode');
                    }}
                    endDecorator={<ArrowDropDownIcon />}
                    sx={[
                      { fontWeight: 'lg', '& svg': { transition: '0.2s' } },
                      donorOrderBy === 'pincode' && donorOrder === 'desc'
                        ? { '& svg': { transform: 'rotate(0deg)' } }
                        : { '& svg': { transform: 'rotate(180deg)' } },
                    ]}
                  >
                    Pincode
                  </Link>
                </th>
                <th aria-label="last" style={{ width: 80, padding: '12px 6px' }} />
              </tr>
            </thead>
            <tbody>
              {filteredDonors.length > 0 ? (
                [...filteredDonors]
                  .sort(getComparator(donorOrder, donorOrderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((donor) => (
                    <tr key={donor.id}>
                      <td style={{ textAlign: 'center', width: 120 }}>
                        <Checkbox
                          size="sm"
                          checked={selected.includes(donor.id.toString())}
                          color={selected.includes(donor.id.toString()) ? 'primary' : undefined}
                          onChange={(event) => {
                            setSelected((ids) =>
                              event.target.checked ? ids.concat(donor.id.toString()) : ids.filter((itemId) => itemId !== donor.id.toString()),
                            );
                          }}
                          slotProps={{ checkbox: { sx: { textAlign: 'left' } } }}
                          sx={{ verticalAlign: 'text-bottom' }}
                        />
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.id}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.name ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.phone ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.email ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.city ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.address ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.pan ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.donorType ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.state ?? '-'}</Typography>
                      </td>
                      <td>
                        <Typography level="body-xs">{donor.pincode ?? '-'}</Typography>
                      </td>
                      <td>
                        <Box sx={{ display: 'flex', gap: 2, px: 1, alignItems: 'center' }}>
                          <Link
                            level="body-xs"
                            component="a"
                            href={`/donation/donor/details?id=${donor.id}`}
                          >
                            View
                          </Link>
                        </Box>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={8} style={{ textAlign: "center" }}>No donor found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Box>
        <Table>
          <tfoot>
            <tr>
              <td colSpan={13} style={{ position: 'sticky', bottom: 0, background: '#fff', zIndex: 1, backgroundColor: 'var(--joy-palette-background-level1)' }}>
                <Box
                  sx={{
                    maxWidth: '100%',
                    overflowX: 'auto',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      justifyContent: 'flex-end',
                      px: 2,
                      py: 1,
                      minWidth: '600px',
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                        <Option value={25}>25</Option>
                        <Option value={50}>50</Option>
                        <Option value={100}>100</Option>
                      </Select>
                    </FormControl>
                    <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
                      {`${filteredDonors.length === 0 ? 0 : page * rowsPerPage + 1}–${getLabelDisplayedRowsTo()} of ${filteredDonors.length}`}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          filteredDonors.length !== -1
                            ? page >= Math.ceil(filteredDonors.length / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </td>
            </tr>
          </tfoot>
        </Table>
      </Sheet>
    </React.Fragment>
  );
}

