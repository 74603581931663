import React, { useState } from 'react';
import { Box, Button, Typography, FormControl, FormLabel, Stack, Select, Option, Card } from '@mui/joy';
import dayjs from 'dayjs';

import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import JoyDatePicker from "../components/common/JoyDatePicker";

const Report = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedRange, setSelectedRange] = useState('Last 7 Days');

  React.useEffect(() => {
    const today = dayjs();
    switch (selectedRange) {
      case 'Today':
        setStartDate(today.format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'Last 7 Days':
        setStartDate(today.subtract(6, 'day').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'Last 1 Month':
        setStartDate(today.subtract(1, 'month').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'Last 3 Months':
        setStartDate(today.subtract(3, 'month').format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
        break;
      case 'Custom':
      default:
        break;
    }
  }, [selectedRange]);

  const handleDownload = async () => {
    setError('');
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (!startDate || !endDate) {
      setError('Please select both start and end dates.');
      return;
    }

    if (end.isBefore(start)) {
      setError('End date cannot be before start date.');
      return;
    }
    if (start.isAfter(dayjs()) || end.isAfter(dayjs())) {
      setError('Dates cannot be in the future.');
      return;
    }

    if (end.diff(start, 'day') > 366) {
      setError('Maximum allowed range is 1 year.');
      return;
    }

    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/reports/donations?start=${startDate}&end=${endDate}`, {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Failed to download report');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `donations_report_${startDate}_to_${endDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (err) {
      console.error(err);
      setError('An error occurred while downloading the report.');
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Header />
      <Sidebar />
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2, md: 6 },
          pt: {
            xs: "calc(12px + var(--Header-height))",
            sm: "calc(12px + var(--Header-height))",
            md: 3,
          },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,
          bgcolor: "background.body",
        }}
      >
        <Box sx={{ flex: 1, width: "100%" }}>
          <Box
            sx={{
              position: "sticky",
              top: { sm: -100, md: -110 },
              bgcolor: "background.body",
            }}
          >


            <Box sx={{ px: { xs: 2, md: 6 } }}>
              <Breadcrumbs
                size="sm"
                aria-label="breadcrumbs"
                separator={<ChevronRightRoundedIcon fontSize="small" />}
                sx={{ pl: 0 }}
              >
                <Link
                  underline="none"
                  color="neutral"
                  href="#some-link"
                  aria-label="Home"
                >
                  <HomeRoundedIcon />
                </Link>
                <Typography color="primary" sx={{ fontWeight: 500, fontSize: 12 }}>
                  Report
                </Typography>
              </Breadcrumbs>
            </Box>

            <Stack spacing={4} sx={{ display: "flex", maxWidth: "800px", mx: "auto", px: { xs: 2, md: 6 }, py: { xs: 2, md: 3 } }}>
              <Card>
                <Box>
                  <Typography level="h3" component="h2" sx={{ mb: 2 }}>
                    Download Report
                  </Typography>
                  <Stack direction="row" spacing={2} sx={{ my: 1 }}>
                    <FormControl>
                      <FormLabel>Report Type</FormLabel>
                      <Select size="sm" sx={{ minWidth: 200 }} value="Donation" disabled>
                        <Option value="Donation">Donation</Option>
                        <Option value="Expense" disabled>Expense (Coming Soon)</Option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel>Select Range</FormLabel>
                      <Select size='sm' sx={{ minWidth: 200 }} value={selectedRange} onChange={(_, val) => setSelectedRange(val || 'Custom')}>
                        <Option value="Today">Today</Option>
                        <Option value="Last 7 Days">Last 7 Days</Option>
                        <Option value="Last 1 Month">Last 1 Month</Option>
                        <Option value="Last 3 Months">Last 3 Months</Option>
                        <Option value="Custom">Custom</Option>
                      </Select>
                    </FormControl>
                  </Stack>

                  {selectedRange === 'Custom' && (
                    <Stack direction="row" spacing={2} sx={{ my: 2 }}>
                      <FormControl>
                        <FormLabel>Start Date</FormLabel>
                        <JoyDatePicker
                          name="startDate"
                          value={startDate ? dayjs(startDate) : null}
                          onChange={(newValue) => setStartDate(newValue ? newValue.format('YYYY-MM-DD') : '')}
                          maxDate={dayjs()}
                          format="YYYY-MM-DD"
                          slotProps={{
                            textField: {
                              size: "small",
                              sx: {
                                height: "32px",
                                minHeight: "32px",
                                fontSize: "0.875rem",
                                padding: "5px 10px",
                              },
                            },
                          }}
                          sx={{
                            height: "32px",
                            minHeight: "32px",
                            width: "200px",
                            "& .MuiInputBase-root": { height: "32px" }
                          }}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>End Date</FormLabel>
                        <JoyDatePicker
                          name="endDate"
                          value={endDate ? dayjs(endDate) : null}
                          onChange={(newValue) => setEndDate(newValue ? newValue.format('YYYY-MM-DD') : '')}
                          minDate={startDate ? dayjs(startDate) : undefined}
                          maxDate={dayjs()}
                          format="YYYY-MM-DD"
                          slotProps={{
                            textField: {
                              size: "small",
                              sx: {
                                height: "32px",
                                minHeight: "32px",
                                fontSize: "0.875rem",
                                padding: "5px 10px",
                              },
                            },
                          }}
                          sx={{
                            height: "32px",
                            minHeight: "32px",
                            width: "200px",
                            "& .MuiInputBase-root": { height: "32px" }
                          }}
                        />
                      </FormControl>
                    </Stack>
                  )}

                  <> </>
                  {error && <Typography color="danger">{error}</Typography>}
                  <Button loading={isDownloading} onClick={handleDownload} sx={{ mt: 1 }}>
                    {isDownloading ? 'Downloading...' : 'Download Report'}
                  </Button>
                </Box>
              </Card>
            </Stack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Report;
