import * as React from "react";
import { Box, Typography, Grid, Select, Option, Stack, FormControl, FormLabel } from "@mui/joy";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Chip from "@mui/joy/Chip";
import { Button } from "@mui/joy";
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import Header from "../components/common/Header";
import Sidebar from "../components/common/Sidebar";
import CircularProgress from "@mui/joy/CircularProgress";
import dayjs, { Dayjs } from "dayjs";
import JoyDatePicker from "../components/common/JoyDatePicker";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export default function Dashboard() {
   const [timeRange, setTimeRange] = React.useState("last30days");
   const [customStartDate, setCustomStartDate] = React.useState<Dayjs | null>(null);
   const [customEndDate, setCustomEndDate] = React.useState<Dayjs | null>(null);
   const [applyCustomRange, setApplyCustomRange] = React.useState(false);
   const [insights, setInsights] = React.useState<any>(null);
   const [loading, setLoading] = React.useState(true);

   React.useEffect(() => {
      const fetchInsights = async () => {
         setLoading(true);
         try {
            const params = new URLSearchParams();
            if (timeRange !== "last30days" && timeRange !== "custom") {
               const today = new Date();
               let startDate = new Date();
               if (timeRange === "today") {
                  startDate = today;
               } else if (timeRange === "last7days") {
                  startDate.setDate(today.getDate() - 7);
               }
               params.append("start", startDate.toISOString().split("T")[0]);
               params.append("end", today.toISOString().split("T")[0]);
            }


            if (timeRange === "custom") {
               if (!customStartDate || !customEndDate || !applyCustomRange) {
                  setLoading(false);
                  return;
               }
               params.append("start", customStartDate.format("YYYY-MM-DD"));
               params.append("end", customEndDate.format("YYYY-MM-DD"));
            }

            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/insights?${params.toString()}`, {
               credentials: "include",
            });

            if (!response.ok) {
               if (response.status === 401) {
                  const redirectUrl = encodeURIComponent(window.location.href);
                  window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
                  return;
               }
               throw new Error("Failed to fetch insights");
            }

            const data = await response.json();
            setInsights(data);
         } catch (err) {
            console.error("Failed to fetch insights", err);
         } finally {
            setLoading(false);
         }
      };

      fetchInsights();
   }, [timeRange, customStartDate, customEndDate, applyCustomRange]);

   return (
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
         <Header />
         <Sidebar />
         <Box
            component="main"
            className="MainContent"
            sx={{
               pt: { xs: "calc(12px + var(--Header-height))", md: 3 },
               pb: { xs: 2, sm: 2, md: 3 },
               px: { xs: 2, md: 6 },
               flex: 1,
               display: "flex",
               flexDirection: "column",
               minWidth: 0,
               height: "100dvh",
               gap: 1,
               overflow: "auto",
               bgcolor: "background.body",
            }}
         >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
               <Typography level="h2">Dashboard Overview</Typography>
               <Stack direction="row" spacing={2} alignItems="center">
                  <Select
                     value={timeRange}
                     onChange={(_, value) => {
                        setTimeRange(value || "last30days");
                        setApplyCustomRange(false);
                     }}
                     size="sm"
                     sx={{ minWidth: 150 }}
                  >
                     <Option value="today">Today</Option>
                     <Option value="last7days">Last 7 Days</Option>
                     <Option value="last30days">Last 30 Days</Option>
                     <Option value="custom">Custom</Option>
                  </Select>
                  {timeRange === "custom" && (
                     <Stack direction="row" spacing={2}>
                        <JoyDatePicker
                           name="startDate"
                           value={customStartDate}
                           onChange={(newValue) =>
                              setCustomStartDate(newValue ? dayjs(newValue.format("YYYY-MM-DD")) : null)
                           }
                           maxDate={dayjs()}
                           format="YYYY-MM-DD"
                           slotProps={{
                              textField: {
                                 size: "small",
                                 sx: {
                                    height: "32px",
                                    minHeight: "32px",
                                    fontSize: "0.875rem",
                                    padding: "5px 10px",
                                 },
                              },
                           }}
                           sx={{
                              height: "32px",
                              minHeight: "32px",
                              width: "200px",
                              "& .MuiInputBase-root": { height: "32px" },
                           }}
                        />
                        <JoyDatePicker
                           name="endDate"
                           value={customEndDate}
                           onChange={(newValue) =>
                              setCustomEndDate(newValue ? dayjs(newValue.format("YYYY-MM-DD")) : null)
                           }
                           minDate={customStartDate || undefined}
                           maxDate={dayjs()}
                           format="YYYY-MM-DD"
                           slotProps={{
                              textField: {
                                 size: "small",
                                 sx: {
                                    height: "32px",
                                    minHeight: "32px",
                                    fontSize: "0.875rem",
                                    padding: "5px 10px",
                                 },
                              },
                           }}
                           sx={{
                              height: "32px",
                              minHeight: "32px",
                              width: "200px",
                              "& .MuiInputBase-root": { height: "32px" },
                           }}
                        />
                        <Button
                           size="sm"
                           variant="solid"
                           onClick={() => setApplyCustomRange(true)}
                           disabled={!customStartDate || !customEndDate}
                           sx={{ height: "32px", alignSelf: "flex-end" }}
                        >
                           Apply
                        </Button>
                     </Stack>
                  )}
               </Stack>
            </Box>


            {loading ? (
               <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress size="lg" />
               </Box>
            ) : insights?.totalDonations?.value === 0 ? (
               <Box
                  sx={{
                     flex: 1,
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                     justifyContent: "center",
                     textAlign: "center",
                     p: 4,
                  }}
               >
                  {/* <img
                     src="/empty-graph-animation.svg"
                     alt="No data"
                     style={{ width: "300px", marginBottom: "2rem" }}
                  /> */}
                  <Typography level="h3" sx={{ mb: 1 }}>
                     No Data Available
                  </Typography>
                  <Typography level="body-md" color="neutral">
                     There are no donations recorded for this time range.
                     <br />
                     Try selecting a different time range.
                  </Typography>
               </Box>
            ) : (
               <>
                  <Box sx={{ mt: 2 }}>
                     <Typography level="h4" sx={{ mb: 2 }}>Overview Metrics</Typography>
                     <Grid container spacing={2} alignItems="stretch">
                        {insights && [
                           {
                              label: "Total Donations",
                              value: `₹${insights.totalDonations.value}`,
                              change: `${insights.totalDonations.change > 0 ? '▲ +' : insights.totalDonations.change < 0 ? '▼ ' : ''}${insights.totalDonations.change}%`,
                              color:
                                 insights.totalDonations.change > 0
                                    ? "success"
                                    : insights.totalDonations.change < 0
                                       ? "danger"
                                       : "neutral",
                           },
                           {
                              label: "New Donors",
                              value: `${insights.newDonors.value}`,
                              change: `${insights.newDonors.change > 0 ? '▲ +' : insights.newDonors.change < 0 ? '▼ ' : ''}${insights.newDonors.change}%`,
                              color:
                                 insights.newDonors.change > 0
                                    ? "success"
                                    : insights.newDonors.change < 0
                                       ? "danger"
                                       : "neutral",
                           },
                           {
                              label: "Avg. Donation",
                              value: `₹${insights.averageDonation.value}`,
                              change: `${insights.averageDonation.change > 0 ? '▲ +' : insights.averageDonation.change < 0 ? '▼ ' : ''}${insights.averageDonation.change}%`,
                              color:
                                 insights.averageDonation.change > 0
                                    ? "success"
                                    : insights.averageDonation.change < 0
                                       ? "danger"
                                       : "neutral",
                           },
                           { label: "Recent Donation", value: `₹${insights.recentDonation?.value}`, change: insights.recentDonation?.date, color: "primary" },
                        ].map((stat) => (
                           <Grid xs={12} sm={6} md={3} lg={3} key={stat.label}>
                              <Card variant="outlined" sx={{ height: '100%' }}>
                                 <CardContent>
                                    <Typography level="body-sm">{stat.label}</Typography>
                                    <Typography level="h3">{stat.value}</Typography>
                                    {stat.change && <Chip size="sm" color={stat.color as any}>{stat.change}</Chip>}
                                 </CardContent>
                              </Card>
                           </Grid>
                        ))}
                     </Grid>
                  </Box>

                  <Box sx={{ mt: 4 }}>
                     <Typography level="h4" sx={{ mb: 2 }}>Top Insights</Typography>
                     <Grid container spacing={2} alignItems="stretch">
                        {insights && [
                           { label: "Top Donor", value: insights.topDonor?.name, change: `₹${insights.topDonor?.amount}`, color: "success" },
                           { label: "Top Purpose", value: insights.topPurpose },
                           { label: "Top Event", value: insights.topEvent },
                           { label: "Top Head", value: insights.topHead },
                        ].map((stat) => (
                           <Grid xs={12} sm={6} md={3} lg={3} key={stat.label}>
                              <Card variant="outlined" sx={{ height: '100%' }}>
                                 <CardContent>
                                    <Typography level="body-sm">{stat.label}</Typography>
                                    <Typography level="h3">{stat.value}</Typography>
                                    {stat.change && <Chip size="sm" color={stat.color as any}>{stat.change}</Chip>}
                                 </CardContent>
                              </Card>
                           </Grid>
                        ))}
                     </Grid>
                  </Box>

                  <Grid container spacing={2} sx={{ mt: 4 }} alignItems="stretch">
                     <Grid xs={12} sm={12} md={6} lg={6}>
                        <Typography level="h4" sx={{ mb: 2 }}>
                           Donations Over Time
                        </Typography>
                        <LineChart
                           xAxis={[{ scaleType: 'point', data: insights?.donationsOverTime?.map((d: { month: string; amount: number }) => d.month) || [] }]}
                           series={[{ data: insights?.donationsOverTime?.map((d: { month: string; amount: number }) => d.amount) || [], label: 'Donations', color: '#8884d8' }]}
                           height={300}
                        />
                     </Grid>
                     <Grid xs={12} sm={12} md={6} lg={6}>
                        <Typography level="h4" sx={{ mb: 2 }}>
                           Donation Distribution by Payment Method
                        </Typography>
                        <BarChart
                           xAxis={[{ scaleType: 'band', data: ['Cash', 'Online', 'Cheque'] }]}
                           series={[{
                              data: [
                                 insights?.donationBreakdownByPaymentMethod?.Cash || 0,
                                 insights?.donationBreakdownByPaymentMethod?.Online || 0,
                                 insights?.donationBreakdownByPaymentMethod?.Cheque || 0,
                              ], label: 'Amount', color: '#82ca9d'
                           }]}
                           height={300}
                        />
                     </Grid>
                  </Grid>

                  <Box sx={{ mt: 4 }}>
                     <Typography level="h4" sx={{ mb: 2 }}>Payment Breakdown</Typography>
                     <Grid container spacing={2} alignItems="stretch">
                        {insights && [
                           { label: "Cash Donations", value: `₹${insights.cashDonations}` },
                           { label: "Online Donations", value: `₹${insights.onlineDonations}` },
                           { label: "Cheque Donations", value: `₹${insights.chequeDonations}` },
                           { label: "Most Used Payment Method", value: insights.mostUsedPaymentMethod },
                        ].map((stat) => (
                           <Grid xs={12} sm={6} md={3} lg={3} key={stat.label}>
                              <Card variant="outlined" sx={{ height: '100%' }}>
                                 <CardContent>
                                    <Typography level="body-sm">{stat.label}</Typography>
                                    <Typography level="h3">{stat.value}</Typography>
                                 </CardContent>
                              </Card>
                           </Grid>
                        ))}
                     </Grid>
                  </Box>

                  <Box sx={{ mt: 4 }}>
                     <Typography level="h4" sx={{ mb: 2 }}>Donor Metrics</Typography>
                     <Grid container spacing={2} alignItems="stretch">
                        {insights && [
                           { label: "Unique Donors", value: `${insights.uniqueDonors}` },
                           { label: "Repeat Donors", value: `${insights.repeatDonors}` },
                           { label: "Most Active Day", value: insights.mostActiveDay },
                           { label: "City with Highest Donations", value: insights.topCityByDonations },
                        ].map((stat) => (
                           <Grid xs={12} sm={6} md={3} lg={3} key={stat.label}>
                              <Card variant="outlined" sx={{ height: '100%' }}>
                                 <CardContent>
                                    <Typography level="body-sm">{stat.label}</Typography>
                                    <Typography level="h3">{stat.value}</Typography>
                                 </CardContent>
                              </Card>
                           </Grid>
                        ))}
                     </Grid>
                  </Box>

                  {insights?.donationsByTopEvent?.length > 0 && (
                     <Box sx={{ mt: 4 }}>
                        <Typography level="h4" sx={{ mb: 2 }}>Donations by Top Event</Typography>
                        <Grid container spacing={2} alignItems="stretch">
                           {insights.donationsByTopEvent.slice(0, 4).map((event: { eventName: string; count: number }) => (
                              <Grid xs={12} sm={6} md={3} lg={3} key={event.eventName}>
                                 <Card variant="outlined" sx={{ height: '100%' }}>
                                    <CardContent>
                                       <Typography level="body-sm">{event.eventName}</Typography>
                                       <Typography level="h3">{event.count}</Typography>
                                    </CardContent>
                                 </Card>
                              </Grid>
                           ))}
                        </Grid>
                     </Box>
                  )}
               </>
            )}
         </Box>
      </Box>
   );
}
