import * as React from "react";
import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import states from "../data/states.json";
import Typography from "@mui/joy/Typography";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import Autocomplete from "@mui/joy/Autocomplete";
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

import CircularProgress from '@mui/joy/CircularProgress';
import { PersonAddRounded, PersonSearchRounded, TagRounded, CurrencyRupeeRounded, EmailRounded, ChevronRightRounded, HomeRounded } from "@mui/icons-material";

import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import JoyDatePicker from "../components/common/JoyDatePicker";


export default function EditDonation() {
   const [donorOptions, setDonorOptions] = useState<any[]>([]);
   const [searchTerm, setSearchTerm] = useState<string>("");
   const [isSearchLoading, setIsSearchLoading] = useState(false);
   const [isAddingDonor, setIsAddingDonor] = useState(false);
   const [loading, setLoading] = useState(true);

   let Navigate = useNavigate();

   const [donorData, setDonorData] = useState({
      id: "",
      name: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "India",
      pincode: "",
      pan: "",
      email: "",
      donorType: "individual",
   });

   const [errors, setErrors] = useState({
      name: "",
      phone: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      pan: "",
   });

   const [donationData, setDonationData] = useState({
      id: "",
      donorId: null,
      date: "",
      amount: "",
      paymentMethod: "",
      gift: "",
      purpose: "",
      event: "",
      taxReceiptNo: "",
      head: "",
      pan: "",
      email: "",
      status: "",
      note: "",
   });

   const [errorsD, setErrorsD] = useState({
      amount: "",
      date: "",
      paymentMethod: "",
      purpose: "",
      event: "",
   });
   const [isSubmittingD, setIsSubmittingD] = useState(false);
   const [messageD, setMessageD] = useState<string | null>(null);
   const [errorD, setErrorD] = useState<string | null>(null);

   const [isSubmitting, setIsSubmitting] = useState(false);
   const [isEditing, setIsEditing] = useState(false);
   const [isFormEditable, setIsFormEditable] = useState(true);
   const [message, setMessage] = useState<string | null>(null);
   const [error, setError] = useState<string | null>(null);
   const [options, setOptions] = useState({
      purpose: [],
      event: [],
      head: [],
      gift: [],
   });

   const [searchParams] = useSearchParams();
   const donationId = searchParams.get("id");

   const [originalDonorData, setOriginalDonorData] = useState(donorData);
   const [showDonorForm, setShowDonorForm] = useState(false);
   const hasErrors = Object.values(errors).some((error) => error !== "") || !donorData.name || !donorData.phone;

   useEffect(() => {
      const fetchDonation = async () => {
         if (!donationId) return;

         setLoading(true);
         try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/donation/${donationId}`, {
               method: "GET",
               headers: { "Content-Type": "application/json" },
               credentials: "include",
            });

            if (!response.ok) {
               if (response.status === 401) {
                  const redirectUrl = encodeURIComponent(window.location.href);
                  window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
                  return;
               }
               throw new Error("Failed to fetch donation details.");
            }

            const data = await response.json();

            setDonationData(data);
            setDonorData({
               id: data.donorId || "",
               name: data.name || "",
               phone: data.phone || "",
               address: data.address || "",
               city: data.city || "",
               state: data.state || "",
               country: data.country || "India",
               pincode: data.pincode || "",
               pan: data.pan || "",
               email: data.email || "",
               donorType: data.donorType || "individual",
            });
            setOriginalDonorData({
               id: data.donorId || "",
               name: data.name || "",
               phone: data.phone || "",
               address: data.address || "",
               city: data.city || "",
               state: data.state || "",
               country: data.country || "India",
               pincode: data.pincode || "",
               pan: data.pan || "",
               email: data.email || "",
               donorType: data.donorType || "individual",
            });
            setShowDonorForm(true);
         } catch (error) {
            console.error("Error fetching donation:", error);
            setError("Failed to fetch donation details.");
         } finally {
            setLoading(false);
         }
      };

      fetchDonation();
   }, [donationId]);

   useEffect(() => {
      const fetchOptions = async () => {
         const categories = ["purpose", "event", "head", "gift"];
         try {
            const results = await Promise.all(
               categories.map((type) =>
                  fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/options?type=${type}`, {
                     credentials: "include",
                  }).then((res) => res.json())
               )
            );
            const data: any = {};
            categories.forEach((cat, idx) => {
               data[cat] = results[idx];
            });
            setOptions(data);
         } catch (error) {
            console.error("Failed to fetch donation options:", error);
         }
      };

      fetchOptions();
   }, []);

   useEffect(() => {
      if (message || error) {
         const timer = setTimeout(() => {
            setMessage(null);
            setError(null);
         }, 3000);
         return () => clearTimeout(timer);
      }
   }, [message, error]);

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      let errorMsg = "";
      let validatedValue = value;

      switch (name) {
         case "name":
            if (!/^[A-Za-z0-9 ]*$/.test(value) || value.length > 24) {
               errorMsg = "Max 24 characters. Only letters, numbers, and spaces allowed.";
            }
            validatedValue = value.slice(0, 24);
            break;

         case "phone":
            if (!/^[1-9][0-9]{0,9}$/.test(value)) {
               errorMsg = "Must be exactly 10 digits and cannot start with 0.";
            }
            validatedValue = value.slice(0, 10);
            break;

         case "email":
            if (!/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/.test(value)) {
               errorMsg = "Enter a valid email address.";
            }
            break;

         case "address":
            if (!/^[a-zA-Z0-9 ,\-\/]*$/.test(value) || value.length > 50) {
               errorMsg = "Max 50 characters. Only letters, numbers, ',', '-', and '/' allowed.";
            }
            validatedValue = value.slice(0, 50);
            break;

         case "city":
            validatedValue = value.toUpperCase();
            if (!/^[A-Z ]*$/.test(validatedValue) || validatedValue.length > 20) {
               errorMsg = "Max 20 characters. Only uppercase letters and spaces allowed.";
            }
            validatedValue = validatedValue.slice(0, 20);
            break;

         case "pincode":
            if (!/^[0-9]{0,6}$/.test(value)) {
               errorMsg = "Must be exactly 6 digits.";
            }
            validatedValue = value.slice(0, 6);
            break;

         case "pan":
            validatedValue = value.toUpperCase();
            if (!/^[A-Z]{5}[0-9]{4}[A-Z]$/.test(validatedValue)) {
               errorMsg = "PAN must be exactly 10 characters: 5 letters, 4 digits, 1 letter.";
            }
            validatedValue = validatedValue.slice(0, 10);
            break;

         default:
            break;
      }

      setErrors((prev) => ({ ...prev, [name]: errorMsg }));
      setDonorData((prev) => ({ ...prev, [name]: validatedValue }));
   };

   const handleSelectChange = (event: any, newValue: string | null, field: string) => {
      if (["paymentMethod", "purpose", "event", "gift", "head"].includes(field)) {
         setDonationData((prev) => ({ ...prev, [field]: newValue || "" }));
      } else {
         setDonorData((prev) => ({ ...prev, [field]: newValue || "" }));
      }
   };

   const handleAddDonor = async () => {
      setIsSubmitting(true);
      setMessage(null);
      setError(null);

      const { id, ...donorPayload } = donorData;

      try {
         const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/donors`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(donorPayload),
            credentials: "include",
         });

         if (!response.ok) {
            if (response.status === 401) {
               const redirectUrl = encodeURIComponent(window.location.href);
               window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
               return;
            }
            throw new Error("Failed to add donor.");
         }

         const newDonor = await response.json();

         setMessage("Donor added successfully!");
         setDonorData({ ...donorPayload, id: newDonor.donorId });
         setOriginalDonorData(newDonor);
         setIsFormEditable(false);
         setIsEditing(false);
         setShowDonorForm(true);
         setIsAddingDonor(false); // Show "Add New" button again
      } catch (err) {
         setError("Error adding donor. Please try again.");
      } finally {
         setIsSubmitting(false);
      }
   };

   const handleUpdateDonor = async () => {
      setIsSubmitting(true);
      setMessage(null);
      setError(null);

      try {
         const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/donors/${donorData.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(donorData),
            credentials: "include",
         });

         if (!response.ok) {
            if (response.status === 401) {
               const redirectUrl = encodeURIComponent(window.location.href);
               window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
               return;
            }
            throw new Error("Failed to update donor.");
         }

         setMessage("Donor updated successfully!");
         setOriginalDonorData(donorData); // Update original data
         setIsFormEditable(false); // Make form fields read-only
         setIsEditing(false); // Exit edit mode
      } catch (err) {
         setError("Error updating donor. Please try again.");
      } finally {
         setIsSubmitting(false);
      }
   };

   // Function to fetch donors from the API
   const fetchDonors = async (query: string) => {
      setIsSearchLoading(true);

      try {
         const response = await fetch(
            `${process.env.REACT_APP_BACKEND_API_URL}/api/search/doner?q=${query}`, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
            credentials: "include",
         });

         if (!response.ok) {
            if (response.status === 401) {
               const redirectUrl = encodeURIComponent(window.location.href);
               window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
               return;
            }
            throw new Error("Failed to search donors.");
         }

         const data = await response.json();
         //setDonorOptions(data.map((donor: any) => donor.name+" ("+donor.email+", "+donor.phone+")"));
         setDonorOptions(Array.isArray(data) ? data : []);
      } catch (error) {
         console.error("Error fetching donors:", error);
         setDonorOptions([]);
      } finally {
         setIsSearchLoading(false);
      }
   };

   const handleDonationInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      let errorMsg = "";
      let validatedValue = value;

      switch (name) {
         case "amount":
            if (!/^\d*\.?\d*$/.test(value)) {
               return; // Prevent invalid character entry
            }
            if (value && parseFloat(value) < 0) {
               errorMsg = "Enter a valid positive amount.";
            }
            break;

         case "taxReceiptNo":
            if (!/^\d*$/.test(value)) {
               return; // Prevent non-numeric character entry
            }
            break;
         case "date":
            if (!value) {
               errorMsg = "Date is required.";
            }
            break;

         default:
            break;
      }

      setErrorsD((prev) => ({ ...prev, [name]: errorMsg }));
      setDonationData((prev) => ({ ...prev, [name]: validatedValue }));
   };

   const handleSaveDonation = async () => {
      setIsSubmittingD(true);
      setMessageD("");
      setErrorD(null);

      if (!donationData.amount || !donationData.date || !donationData.paymentMethod) {
         setErrorD("Please fill in all required fields.");
         setIsSubmittingD(false);
         return;
      }

      // Convert amount to a float number
      const formattedAmount = parseFloat(donationData.amount);
      if (isNaN(formattedAmount) || formattedAmount <= 0) {
         setErrorD("Enter a valid positive amount.");
         setIsSubmittingD(false);
         return;
      }

      // Convert date to IST (Indian Standard Time) format
      const formattedDate = dayjs(donationData.date).tz("Asia/Kolkata").format("YYYY-MM-DDTHH:mm:ssZ");

      const formattedDonation = {
         ...donationData,
         amount: formattedAmount, // Convert amount to float
         date: formattedDate, // Ensure correct format with IST
         donorId: Number(donorData.id),
      };

      try {
         const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/donation/${donationData.id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formattedDonation),
            credentials: "include",
         });

         if (!response.ok) {
            if (response.status === 401) {
               const redirectUrl = encodeURIComponent(window.location.href);
               window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
               return;
            }
            throw new Error("Failed to update donation.");
         }

         setMessageD("Donation details updated successfully!");
         setShowDonorForm(false);
         setDonorData({
            id: "",
            name: "",
            phone: "",
            address: "",
            city: "",
            state: "",
            country: "India",
            pincode: "",
            pan: "",
            email: "",
            donorType: "individual",
         });
         setDonationData({
            id: "",
            donorId: null,
            date: "",
            amount: "",
            paymentMethod: "",
            gift: "",
            purpose: "",
            event: "",
            taxReceiptNo: "",
            head: "",
            pan: "",
            email: "",
            status: "",
            note: "",
         });
      } catch (err) {
         setErrorD("Error saving donation. Please try again.");
      } finally {
         setIsSubmittingD(false);
      }
   };

   return (
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
         <Header />
         <Sidebar />
         <Box
            component="main"
            className="MainContent"
            sx={{
               pt: { xs: "calc(12px + var(--Header-height))", md: 3 },
               pb: { xs: 2, sm: 2, md: 3 },
               flex: 1,
               display: "flex",
               flexDirection: "column",
               minWidth: 0,
               height: "100dvh",
               gap: 1,
               overflow: "auto",
               bgcolor: "background.body",
            }}
         >
            <Box sx={{ flex: 1, width: "100%" }}>
               <Box
                  sx={{
                     position: "sticky",
                     top: { sm: -100, md: -110 },
                     bgcolor: "background.body",
                     zIndex: 9995,
                  }}
               >
                  <Box sx={{ px: { xs: 2, md: 6 } }}>
                     <Breadcrumbs
                        size="sm"
                        aria-label="breadcrumbs"
                        separator={<ChevronRightRounded fontSize="small" />}
                        sx={{ pl: 0 }}
                     >
                        <Link
                           underline="none"
                           color="neutral"
                           href="#some-link"
                           aria-label="Home"
                        >
                           <HomeRounded />
                        </Link>
                        <Link
                           underline="hover"
                           color="neutral"
                           href="/donation"
                           sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                           Donation
                        </Link>
                        <Link
                           underline="hover"
                           color="neutral"
                           href={`/donation/details?id=${donationId}`}
                           sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                           ID - {donationId}
                        </Link>
                        <Typography
                           color="primary"
                           sx={{ fontWeight: 500, fontSize: 12 }}
                        >
                           Edit
                        </Typography>
                     </Breadcrumbs>
                     <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                        Edit Donation
                     </Typography>
                  </Box>
               </Box>
               <Stack
                  spacing={4}
                  sx={{
                     display: "flex",
                     maxWidth: "800px",
                     mx: "auto",
                     px: { xs: 2, md: 6 },
                     py: { xs: 2, md: 3 },
                  }}
               >
                  <Card>
                     <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Edit Donation Details</Typography>
                        <Typography level="body-sm">
                           Edit donation details here. You can also search and update doner profile or add a new and then update rest of the donation details.
                        </Typography>
                        {(loading || !showDonorForm) ? (
                           null
                        ): (
                              <Stack direction = "row" spacing = { 2 } sx = {{ width: 300, mt: 2, alignItems: "flex-end" }}>
                           <FormControl sx={{ flexGrow: 2 }}>
                              <FormLabel >ID</FormLabel>
                              <Input
                                 size="sm"
                                 placeholder="ID"
                                 name="id"
                                 value={donationData.id}
                                 readOnly
                                 sx={{ width: 80 }}
                              />
                              {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                           </FormControl>

                           <FormControl id="search-donor">
                              <FormLabel>Search donor</FormLabel>
                              <Autocomplete
                                 placeholder="Search donor"
                                 type="search"
                                 freeSolo
                                 size="sm"
                                 disableClearable
                                 options={donorOptions.map((donor: any) => donor.email)}
                                 value={searchTerm}
                                 startDecorator={<PersonSearchRounded />}
                                 onInputChange={(event, newValue) => {
                                    setSearchTerm(newValue);
                                    if (newValue.length >= 3) fetchDonors(newValue);
                                 }}
                                 isOptionEqualToValue={(option, value) => option === value}
                                 filterOptions={(options) => options}
                                 onChange={(event, newValue) => {
                                    if (newValue) {
                                       setMessage(null);
                                       setError(null);
                                       setErrors({ name: "", phone: "", email: "", address: "", city: "", state: "", pincode: "", pan: "" });

                                       const selectedDonor = donorOptions.find((donor: any) => donor.email === newValue);
                                       setIsFormEditable(false);
                                       setDonorData({
                                          id: selectedDonor.id,
                                          name: selectedDonor.name,
                                          phone: selectedDonor.phone,
                                          address: selectedDonor.address,
                                          city: selectedDonor.city,
                                          state: selectedDonor.state,
                                          country: selectedDonor.country,
                                          pincode: selectedDonor.pincode,
                                          pan: selectedDonor.pan,
                                          email: selectedDonor.email,
                                          donorType: selectedDonor.donorType,
                                       });
                                    }
                                 }}
                                 endDecorator={
                                    isSearchLoading ? (
                                       <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                                    ) : null
                                 }
                              />
                           </FormControl>
                           <Box sx={{ flexGrow: 1 }}>
                              {(!isAddingDonor || donorData.id || isEditing) && (
                                 <Button
                                    size="sm"
                                    variant="solid"
                                    sx={{ width: 130 }}
                                    startDecorator={<PersonAddRounded />}
                                    onClick={() => {
                                       setMessage(null);
                                       setError(null);
                                       setErrors({ name: "", phone: "", email: "", address: "", city: "", state: "", pincode: "", pan: "" });

                                       setShowDonorForm(true);
                                       setIsFormEditable(true);
                                       setIsAddingDonor(true); // Hide "Add New" button
                                       setDonorData({
                                          id: "",
                                          name: "",
                                          phone: "",
                                          address: "",
                                          city: "",
                                          state: "",
                                          country: "India",
                                          pincode: "",
                                          pan: "",
                                          email: "",
                                          donorType: "individual",
                                       });
                                    }}
                                 >
                                    Add New
                                 </Button>
                              )}
                           </Box>
                     </Stack>
                     )}

                     </Box>
                     {loading ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', py: 10 }}>
                           <CircularProgress size="md" />
                        </Box>
                     ) : (
                        <Box>
                           {/* Doner Profile */}
                           {(showDonorForm || donorData.id) ? (
                              <Stack
                                 direction="row"
                                 spacing={3}
                                 sx={{ display: { xs: "flex" }, my: 1 }}
                              >
                                 <Stack spacing={2} sx={{ flexGrow: 1 }}>
                                    <Stack direction="row" spacing={2}>


                                       <FormControl sx={{ flexGrow: 2 }} error={!!errors.name}>
                                          <FormLabel >Name</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="Full name"
                                             name="name"
                                             value={donorData.name}
                                             onChange={handleInputChange}
                                             readOnly={!isFormEditable}
                                          />
                                          {errors.name && <FormHelperText>{errors.name}</FormHelperText>}
                                       </FormControl>


                                       <FormControl sx={{ flexGrow: 1 }} error={!!errors.phone}>
                                          <FormLabel>Phone</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="e.g., 1234567890"
                                             name="phone"
                                             value={donorData.phone}
                                             onChange={handleInputChange}
                                             readOnly={!isFormEditable}
                                          />
                                          {errors.phone && <FormHelperText>{errors.phone}</FormHelperText>}
                                       </FormControl>

                                       <FormControl sx={{ flexGrow: 1 }} error={!!errors.email}>
                                          <FormLabel>Email</FormLabel>
                                          <Input
                                             size="sm"
                                             type="email"
                                             placeholder="username@example.com"
                                             name="email"
                                             value={donorData.email}
                                             onChange={handleInputChange}
                                             readOnly={!isFormEditable}
                                          />
                                          {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
                                       </FormControl>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                       <FormControl sx={{ flexGrow: 1 }} error={!!errors.address}>
                                          <FormLabel>Address</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="e.g., 1,000"
                                             name="address"
                                             value={donorData.address}
                                             onChange={handleInputChange}
                                             readOnly={!isFormEditable}
                                          />
                                          {errors.address && <FormHelperText>{errors.address}</FormHelperText>}
                                       </FormControl>
                                       <FormControl error={!!errors.city}>
                                          <FormLabel>City</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="e.g., KANPUR"
                                             name="city"
                                             value={donorData.city}
                                             onChange={handleInputChange}
                                             readOnly={!isFormEditable}
                                          />
                                          {errors.city && <FormHelperText>{errors.city}</FormHelperText>}
                                       </FormControl>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                       <FormControl>
                                          <FormLabel>State</FormLabel>
                                          <Select
                                             size="sm"
                                             placeholder="Select"
                                             value={donorData.state}
                                             onChange={(event, newValue) => handleSelectChange(event, newValue, "state")}
                                             disabled={!isFormEditable}
                                          >
                                             {states.map((state) => (
                                                <Option key={state} value={state}>{state}</Option>
                                             ))}
                                          </Select>
                                       </FormControl>
                                       <FormControl error={!!errors.pincode}>
                                          <FormLabel>Pincode</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="e.g., 000000"
                                             name="pincode"
                                             value={donorData.pincode}
                                             onChange={handleInputChange}
                                             readOnly={!isFormEditable}
                                          />
                                          {errors.pincode && <FormHelperText>{errors.pincode}</FormHelperText>}
                                       </FormControl>
                                       <FormControl error={!!errors.pan}>
                                          <FormLabel>PAN</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="ABCDE1234F"
                                             name="pan"
                                             value={donorData.pan}
                                             onChange={handleInputChange}
                                             readOnly={!isFormEditable}
                                          />
                                          {errors.pan && <FormHelperText>{errors.pan}</FormHelperText>}
                                       </FormControl>
                                       <FormControl sx={{ flexGrow: 1 }}>
                                          <FormLabel>Type</FormLabel>
                                          <Select
                                             size="sm"
                                             placeholder="Select"
                                             value={donorData.donorType}
                                             onChange={(event, newValue) => handleSelectChange(event, newValue, "donorType")}
                                             disabled={!isFormEditable}
                                          >
                                             <Option value="individual">Individual</Option>
                                             <Option value="corporate">Corporate</Option>
                                             <Option value="nonprofit">Non Profit</Option>
                                             <Option value="other">Other</Option>
                                          </Select>
                                       </FormControl>
                                    </Stack>
                                 </Stack>
                              </Stack>
                           ) : (
                              null
                           )}
                           {message && <Typography level="body-sm" sx={{ color: "green", mt: 1 }}>{message}</Typography>}
                           {error && <Typography level="body-sm" sx={{ color: "red", mt: 1 }}>{error}</Typography>}
                           <CardOverflow>
                              {(showDonorForm || donorData.id) && (
                                 <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                                    {isFormEditable && (
                                       <Button
                                          size="sm"
                                          variant="outlined"
                                          color="neutral"
                                          onClick={() => {
                                             if (!originalDonorData.id) {
                                                setShowDonorForm(false);
                                                setIsAddingDonor(false); // Show "Add New" button again
                                                setDonorData({
                                                   id: "",
                                                   name: "",
                                                   phone: "",
                                                   address: "",
                                                   city: "",
                                                   state: "",
                                                   country: "India",
                                                   pincode: "",
                                                   pan: "",
                                                   email: "",
                                                   donorType: "individual",
                                                });
                                             } else {
                                                setDonorData(originalDonorData);
                                                setIsEditing(false);
                                                setIsFormEditable(false);
                                             }
                                          }}
                                       >
                                          Cancel
                                       </Button>
                                    )}
                                    {donorData.id ? (
                                       isEditing ? (
                                          <Button size="sm" variant="solid" onClick={handleUpdateDonor} disabled={isSubmitting || hasErrors}>
                                             {isSubmitting ? "Updating..." : "Update"}
                                          </Button>
                                       ) : (
                                          <Button size="sm" variant="outlined" onClick={() => {
                                             setOriginalDonorData(donorData); // Store the current donor data before editing
                                             setIsEditing(true);
                                             setIsFormEditable(true);
                                          }}>
                                             Edit
                                          </Button>
                                       )
                                    ) : (
                                       <Button size="sm" variant="solid" onClick={handleAddDonor} disabled={isSubmitting || hasErrors}>
                                          {isSubmitting ? "Adding..." : "Add Donor"}
                                       </Button>
                                    )}
                                 </CardActions>
                              )}
                           </CardOverflow>

                           {/* Show Donation Details only if donor ID is present */}
                           {donorData.id && (
                              <Box>
                                 <Divider />
                                 <Stack
                                    direction="row"
                                    spacing={3}
                                    sx={{ display: { xs: "flex" }, my: 1 }}
                                 >
                                    <Stack spacing={2} sx={{ flexGrow: 1, py: 1 }}>

                                       <Stack direction="row" spacing={2}>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Amount</FormLabel>
                                             <Input
                                                size="sm"
                                                name="amount"
                                                value={donationData.amount}
                                                startDecorator={<CurrencyRupeeRounded />}
                                                placeholder="e.g., 1,000"
                                                onChange={handleDonationInputChange}
                                             />
                                          </FormControl>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Payment Method</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                value={donationData.paymentMethod}
                                                onChange={(event, newValue) => handleSelectChange(event, newValue, "paymentMethod")}
                                             >
                                                <Option value="Cash">Cash</Option>
                                                <Option value="Cheque">Cheque</Option>
                                                <Option value="Online">Online</Option>
                                             </Select>
                                          </FormControl>
                                          <FormControl error={!!errorsD.date}>
                                             <FormLabel>Date</FormLabel>
                                             <JoyDatePicker
                                                name="date"
                                                value={donationData.date ? dayjs(donationData.date) : null}
                                                onChange={(newValue) => {
                                                   const formattedDate = newValue ? newValue.format("YYYY-MM-DD") : "";
                                                   setDonationData((prev) => ({ ...prev, date: formattedDate }));
                                                   setErrorsD((prev) => ({ ...prev, date: formattedDate ? "" : "Date is required." }));
                                                }}
                                                format="YYYY-MM-DD"
                                                slotProps={{
                                                   textField: {
                                                      size: "small",
                                                      sx: { height: "32px", minHeight: "32px", fontSize: "0.875rem", padding: "5px 10px" }, // Ensures consistent height
                                                   },
                                                }}
                                                sx={{ height: "32px", minHeight: "32px", "& .MuiInputBase-root": { height: "32px" } }}
                                             />
                                             {errorsD.date && <FormHelperText>{errorsD.date}</FormHelperText>}
                                          </FormControl>
                                       </Stack>

                                       <Stack direction="row" spacing={2}>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Purpose</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                value={donationData.purpose}
                                                onChange={(event, newValue) => handleSelectChange(event, newValue, "purpose")}
                                             >
                                                {options.purpose.map((opt: any) => (
                                                   <Option key={opt.id} value={opt.value}>{opt.value}</Option>
                                                ))}
                                             </Select>
                                          </FormControl>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Event</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                value={donationData.event}
                                                onChange={(event, newValue) => handleSelectChange(event, newValue, "event")}
                                             >
                                                {options.event.map((opt: any) => (
                                                   <Option key={opt.id} value={opt.value}>{opt.value}</Option>
                                                ))}
                                             </Select>
                                          </FormControl>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Tax Receipt No.</FormLabel>
                                             <Input
                                                size="sm"
                                                name="taxReceiptNo"
                                                startDecorator={<TagRounded />}
                                                value={donationData.taxReceiptNo}
                                                placeholder="e.g., 2025068"
                                                onChange={handleDonationInputChange}
                                             />
                                          </FormControl>
                                       </Stack>
                                       <Stack direction="row" spacing={2}>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Gift</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                value={donationData.gift}
                                                onChange={(event, newValue) => handleSelectChange(event, newValue, "gift")}
                                             >
                                                {options.gift.map((opt: any) => (
                                                   <Option key={opt.id} value={opt.value}>{opt.value}</Option>
                                                ))}
                                             </Select>
                                          </FormControl>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Head</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                defaultValue="Bhisma"
                                                value={donationData.head}
                                                onChange={(event, newValue) => handleSelectChange(event, newValue, "head")}
                                             >
                                                {options.head.map((opt: any) => (
                                                   <Option key={opt.id} value={opt.value}>{opt.value}</Option>
                                                ))}
                                             </Select>
                                          </FormControl>
                                       </Stack>

                                       <div>
                                          <FormControl sx={{ display: { sm: "contents" } }}>
                                             <FormLabel>Note</FormLabel>
                                             <Textarea
                                                size="sm"
                                                minRows={2}
                                                sx={{ mt: 1.5 }}
                                                placeholder="Add a note"
                                             />
                                          </FormControl>
                                       </div>
                                    </Stack>
                                 </Stack>
                                 {messageD && <Typography level="body-sm" sx={{ color: "green", mt: 1 }}>{messageD}</Typography>}
                                 {errorD && <Typography level="body-sm" sx={{ color: "red", mt: 1 }}>{errorD}</Typography>}
                                 <CardOverflow
                                    sx={{ borderTop: "1px solid", borderColor: "divider" }}
                                 >
                                    <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                                       <Button size="sm" variant="outlined" color="neutral">
                                          Cancel
                                       </Button>
                                       <Button
                                          size="sm"
                                          variant="solid"
                                          onClick={handleSaveDonation}
                                          disabled={isSubmittingD || Object.values(errorsD).some((error) => error !== "")}
                                       >
                                          {isSubmittingD ? "Updating..." : "Update"}
                                       </Button>
                                    </CardActions>
                                 </CardOverflow>
                              </Box>
                           )}
                           {messageD && !donorData.id && (
                              <Box sx={{ mt: 2, textAlign: "center" }}>
                                 <Typography level="body-sm" sx={{ color: "green", mb: 2 }}>
                                    {messageD}
                                 </Typography>
                                 <Button
                                    size="sm"
                                       variant="solid"
                                       sx={{my:5}}
                                    onClick={() => {
                                       Navigate("/donation");
                                    }}
                                 >
                                    All Records
                                 </Button>
                              </Box>
                           )}
                        </Box>
                     )}
                  </Card>
               </Stack>
            </Box>
         </Box>
      </Box>
   );
}

