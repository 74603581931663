import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import states from "../data/states.json";
import Typography from "@mui/joy/Typography";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import Link from "@mui/joy/Link";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardOverflow from "@mui/joy/CardOverflow";
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useSearchParams } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);

import CircularProgress from '@mui/joy/CircularProgress';
import { PersonAddRounded, PersonSearchRounded, TagRounded, CurrencyRupeeRounded, EmailRounded, ChevronRightRounded, HomeRounded } from "@mui/icons-material";

import Sidebar from "../components/common/Sidebar";
import Header from "../components/common/Header";
import JoyDatePicker from "../components/common/JoyDatePicker";

export default function DonationDetails() {
   const [searchParams] = useSearchParams();
   const donationId = searchParams.get("id");
   const [messageD, setMessageD] = useState<string | null>(null);

   let navigate = useNavigate();

   const [donorData, setDonorData] = useState({
      id: "",
      name: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "India",
      pincode: "",
      pan: "",
      email: "",
      donorType: "individual",
   });

   const [donationData, setDonationData] = useState({
      id: "",
      donorId: null,
      date: "",
      amount: "",
      paymentMethod: "",
      gift: "",
      purpose: "",
      event: "",
      taxReceiptNo: "",
      head: "",
      pan: "",
      email: "",
      status: "",
      note: "",
   });

   const [message, setMessage] = useState<string | null>(null);
   const [error, setError] = useState<string | null>(null);
   const [loading, setLoading] = useState(true);
   const [options, setOptions] = useState({
      purpose: [],
      event: [],
      head: [],
      gift: [],
   });

   useEffect(() => {
      const fetchDonationDetails = async () => {
         if (!donationId) return;

         try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/donation/${donationId}`, {
               credentials: "include",
            });

            if (!response.ok) {
               if (response.status === 401) {
                  const redirectUrl = encodeURIComponent(window.location.href);
                  window.location.href = `${process.env.REACT_APP_AUTH_WEB_URL}/signin?redirect=${redirectUrl}`;
                  return;
               }
               throw new Error("Failed to fetch donation details");
            }

            const data = await response.json();

            setDonationData({
               id: data.id,
               donorId: data.donorId,
               date: data.date,
               amount: data.amount.toString(),
               paymentMethod: data.paymentMethod,
               gift: data.gift || "",
               purpose: data.purpose || "",
               event: data.event || "",
               taxReceiptNo: data.taxReceiptNo || "",
               head: data.head || "",
               pan: data.pan || "",
               email: data.email || "",
               status: data.status || "",
               note: data.note || "",
            });

            setDonorData({
               id: data.donorId.toString(),
               name: data.name || "",
               phone: data.phone || "",
               address: data.address || "",
               city: data.city || "",
               state: data.state || "",
               country: data.country || "India",
               pincode: data.pincode || "",
               pan: data.pan || "",
               email: data.email || "",
               donorType: data.donorType || "",
            });

            setLoading(false);
         } catch (error) {
            console.error("Error fetching donation details:", error);
            setMessageD("Can't find the donation details for this ID.")
            setLoading(false);
         }
      };

      fetchDonationDetails();
   }, [donationId]);

   useEffect(() => {
      const fetchOptions = async () => {
         const categories = ["purpose", "event", "head", "gift"];
         try {
            const results = await Promise.all(
               categories.map((type) =>
                  fetch(`${process.env.REACT_APP_BACKEND_API_URL}/api/options?type=${type}`, {
                     credentials: "include",
                  }).then((res) => res.json())
               )
            );
            const data: any = {};
            categories.forEach((cat, idx) => {
               data[cat] = results[idx];
            });
            setOptions(data);
         } catch (error) {
            console.error("Failed to fetch donation options:", error);
         }
      };

      fetchOptions();
   }, []);


   return (
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
         <Header />
         <Sidebar />
         <Box
            component="main"
            className="MainContent"
            sx={{
               pt: { xs: "calc(12px + var(--Header-height))", md: 3 },
               pb: { xs: 2, sm: 2, md: 3 },
               flex: 1,
               display: "flex",
               flexDirection: "column",
               minWidth: 0,
               height: "100dvh",
               gap: 1,
               overflow: "auto",
               bgcolor: "background.body",
            }}
         >
            <Box sx={{ flex: 1, width: "100%" }}>
               <Box
                  sx={{
                     position: "sticky",
                     top: { sm: -100, md: -110 },
                     bgcolor: "background.body",
                     zIndex: 9995,
                  }}
               >
                  <Box sx={{ px: { xs: 2, md: 6 } }}>
                     <Breadcrumbs
                        size="sm"
                        aria-label="breadcrumbs"
                        separator={<ChevronRightRounded fontSize="small" />}
                        sx={{ pl: 0 }}
                     >
                        <Link
                           underline="none"
                           color="neutral"
                           href="#some-link"
                           aria-label="Home"
                        >
                           <HomeRounded />
                        </Link>
                        <Link
                           underline="hover"
                           color="neutral"
                           href="/donation"
                           sx={{ fontSize: 12, fontWeight: 500 }}
                        >
                           Donation
                        </Link>
                        <Typography
                           color="primary"
                           sx={{ fontWeight: 500, fontSize: 12 }}
                        >
                           ID - {donationId}
                        </Typography>
                     </Breadcrumbs>
                     <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
                        Donation Details
                     </Typography>
                  </Box>
               </Box>
               <Stack
                  spacing={4}
                  sx={{
                     display: "flex",
                     maxWidth: "800px",
                     mx: "auto",
                     px: { xs: 2, md: 6 },
                     py: { xs: 2, md: 2 },
                  }}
               >
                  <Card>
                     <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Donation Details</Typography>
                        <Typography level="body-sm">
                           Find all the donation details for Donation ID - {donationId}.
                        </Typography>
                     </Box>
                     {/* Doner Profile */}

                     {(loading) ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', py: 10 }}>
                           <CircularProgress size="md" />
                        </Box>
                     ) : (
                        (donationData.id) ? (
                           <Box>
                              <Stack
                                 direction="row"
                                 spacing={3}
                                 sx={{ display: { xs: "flex" }, my: 1 }}
                              >
                                 <Stack spacing={2} sx={{ flexGrow: 1 }}>
                                    <Stack direction="row" spacing={2}>
                                       <FormControl>
                                          <FormLabel >ID</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="ID"
                                             name="id"
                                             readOnly
                                             value={donationData.id}
                                             sx={{ width: 80 }}
                                          />
                                       </FormControl>
                                       <FormControl sx={{ flexGrow: 2 }}>
                                          <FormLabel >Name</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="Full name"
                                             name="name"
                                             value={donorData.name}
                                             readOnly
                                          />
                                       </FormControl>


                                       <FormControl>
                                          <FormLabel>Phone</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="e.g., 1234567890"
                                             name="phone"
                                             value={donorData.phone}
                                          />
                                       </FormControl>

                                       <FormControl sx={{ flexGrow: 1 }}>
                                          <FormLabel>Email</FormLabel>
                                          <Input
                                             size="sm"
                                             type="email"
                                             placeholder="username@example.com"
                                             name="email"
                                             value={donorData.email}
                                          />
                                       </FormControl>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                       <FormControl sx={{ flexGrow: 1 }}>
                                          <FormLabel>Address</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="e.g., 1,000"
                                             name="address"
                                             value={donorData.address}
                                             readOnly
                                          />
                                       </FormControl>
                                       <FormControl>
                                          <FormLabel>City</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="e.g., KANPUR"
                                             name="city"
                                             value={donorData.city}
                                             readOnly
                                          />
                                       </FormControl>
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                       <FormControl>
                                          <FormLabel>State</FormLabel>
                                          <Select
                                             size="sm"
                                             placeholder="Select"
                                             value={donorData.state}
                                             disabled
                                          >
                                             {states.map((state) => (
                                                <Option key={state} value={state}>{state}</Option>
                                             ))}
                                          </Select>
                                       </FormControl>
                                       <FormControl>
                                          <FormLabel>Pincode</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="e.g., 000000"
                                             name="pincode"
                                             value={donorData.pincode}
                                             readOnly
                                          />
                                       </FormControl>
                                       <FormControl>
                                          <FormLabel>PAN</FormLabel>
                                          <Input
                                             size="sm"
                                             placeholder="ABCDE1234F"
                                             name="pan"
                                             value={donorData.pan}
                                             readOnly
                                          />
                                       </FormControl>
                                       <FormControl sx={{ flexGrow: 1 }}>
                                          <FormLabel>Type</FormLabel>
                                          <Select
                                             size="sm"
                                             placeholder="Select"
                                             value={donorData.donorType}
                                             disabled
                                          >
                                             <Option value="individual">Individual</Option>
                                             <Option value="corporate">Corporate</Option>
                                             <Option value="nonprofit">Non Profit</Option>
                                             <Option value="other">Other</Option>
                                          </Select>
                                       </FormControl>
                                    </Stack>
                                 </Stack>
                              </Stack>

                              <Box>
                                 <Divider />
                                 <Stack
                                    direction="row"
                                    spacing={3}
                                    sx={{ display: { xs: "flex" }, my: 1 }}
                                 >
                                    <Stack spacing={2} sx={{ flexGrow: 1, py: 1 }}>

                                       <Stack direction="row" spacing={2}>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Amount</FormLabel>
                                             <Input
                                                size="sm"
                                                name="amount"
                                                value={donationData.amount}
                                                startDecorator={<CurrencyRupeeRounded />}
                                                placeholder="e.g., 1,000"
                                                readOnly
                                             />
                                          </FormControl>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Payment Method</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                value={donationData.paymentMethod}
                                                disabled
                                             >
                                                <Option value="Cash">Cash</Option>
                                                <Option value="Cheque">Cheque</Option>
                                                <Option value="Online">Online</Option>
                                             </Select>
                                          </FormControl>
                                          <FormControl >
                                             <FormLabel>Date</FormLabel>
                                             <JoyDatePicker
                                                name="date"
                                                value={donationData.date ? dayjs(donationData.date) : null}
                                                disabled
                                                format="YYYY-MM-DD"
                                                slotProps={{
                                                   textField: {
                                                      size: "small",
                                                      sx: { height: "32px", minHeight: "32px", fontSize: "0.875rem", padding: "5px 10px" }, // Ensures consistent height
                                                   },
                                                }}
                                                sx={{ height: "32px", minHeight: "32px", "& .MuiInputBase-root": { height: "32px" } }}
                                             />
                                          </FormControl>
                                       </Stack>

                                       <Stack direction="row" spacing={2}>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Purpose</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                value={donationData.purpose}
                                                disabled
                                             >
                                                {options.purpose.map((opt: any) => (
                                                   <Option key={opt.id} value={opt.value}>{opt.value}</Option>
                                                ))}
                                             </Select>
                                          </FormControl>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Event</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                value={donationData.event}
                                                disabled
                                             >
                                                {options.event.map((opt: any) => (
                                                   <Option key={opt.id} value={opt.value}>{opt.value}</Option>
                                                ))}
                                             </Select>
                                          </FormControl>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Tax Receipt No.</FormLabel>
                                             <Input
                                                size="sm"
                                                name="taxReceiptNo"
                                                startDecorator={<TagRounded />}
                                                value={donationData.taxReceiptNo}
                                                placeholder="e.g., 2025068"
                                                disabled
                                             />
                                          </FormControl>
                                       </Stack>
                                       <Stack direction="row" spacing={2}>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Gift</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                value={donationData.gift}
                                                disabled
                                             >
                                                {options.gift.map((opt: any) => (
                                                   <Option key={opt.id} value={opt.value}>{opt.value}</Option>
                                                ))}
                                             </Select>
                                          </FormControl>
                                          <FormControl sx={{ flexGrow: 1 }}>
                                             <FormLabel>Head</FormLabel>
                                             <Select
                                                size="sm"
                                                placeholder="Select"
                                                defaultValue="Bhisma"
                                                value={donationData.head}
                                                disabled
                                             >
                                                {options.head.map((opt: any) => (
                                                   <Option key={opt.id} value={opt.value}>{opt.value}</Option>
                                                ))}
                                             </Select>
                                          </FormControl>
                                       </Stack>

                                       <div>
                                          <FormControl sx={{ display: { sm: "contents" } }}>
                                             <FormLabel>Note</FormLabel>
                                             <Textarea
                                                size="sm"
                                                minRows={2}
                                                sx={{ mt: 1.5 }}
                                                placeholder="Add a note"
                                             />
                                          </FormControl>
                                       </div>
                                    </Stack>
                                 </Stack>
                                 <CardOverflow
                                    sx={{ borderTop: "1px solid", borderColor: "divider" }}
                                 >
                                    <CardActions sx={{ alignSelf: "flex-end", pt: 2 }}>
                                       <Button
                                          size="sm"
                                          variant="outlined"
                                          onClick={() => navigate(`/donation/details/edit?id=${donationId}`)}
                                       >
                                          Edit
                                       </Button>
                                    </CardActions>
                                 </CardOverflow>
                              </Box>
                           </Box>
                        ) : (null)
                     )}
                     {messageD && !donorData.id && (
                        <Box sx={{ mt: 2, textAlign: "center" }}>
                           <Typography level="body-sm" sx={{ color: "green", mb: 2 }}>
                              {messageD}
                           </Typography>
                           <Button
                              size="sm"
                              variant="solid"
                              sx={{ my: 5 }}
                              onClick={() => {
                                 navigate("/donation");
                              }}
                           >
                              All Records
                           </Button>
                        </Box>
                     )}
                  </Card>
               </Stack>
            </Box>
         </Box>
      </Box>
   );
}

